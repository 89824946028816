import React, { FunctionComponent } from 'react';
import { TipoUsuarioValues } from '../types';
import { AppState } from '../store';
import { connect } from 'react-redux';
import { AuthState } from '../reducers/types';
import { useHistory } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DashboardIcon from '@mui/icons-material/Dashboard';
import '../css/trazabilidad.css';

const Trazabilidad: FunctionComponent<{ auth: AuthState }> = ({ auth }) => {
  const router = useHistory();
  const btns: Array<{
    icon: JSX.Element;
    name: string;
    path: string;
    userTypeAccess: Array<TipoUsuarioValues>;
  }> = [
    {
      name: 'Merma',
      path: '/cobros/merma',
      userTypeAccess: [
        TipoUsuarioValues.Admin,
        TipoUsuarioValues.AdminCobros,
        TipoUsuarioValues.Operador,
        TipoUsuarioValues.Transportista,
        TipoUsuarioValues.Visualizador
      ],
      icon: <RvHookupIcon sx={{ fontSize: 40 }} />
    },
    {
      name: 'Faltante',
      path: '/cobros/faltante',
      userTypeAccess: [
        TipoUsuarioValues.Admin,
        TipoUsuarioValues.AdminCobros,
        TipoUsuarioValues.Operador,
        TipoUsuarioValues.Transportista,
        TipoUsuarioValues.Visualizador
      ],
      icon: <CurrencyExchangeIcon sx={{ fontSize: 30, marginRight: 1 }} />
    },
    {
      name: 'Dashboard',
      path: '/cobros/dashboard',
      userTypeAccess: [
        TipoUsuarioValues.Admin,
        TipoUsuarioValues.AdminCobros,
        TipoUsuarioValues.Visualizador
      ],
      icon: <DashboardIcon sx={{ fontSize: 40 }} />
    },
    {
      name: 'Administración',
      path: '/cobros/admin',
      userTypeAccess: [TipoUsuarioValues.Admin, TipoUsuarioValues.AdminCobros],
      icon: <AdminPanelSettingsIcon sx={{ fontSize: 40 }} />
    }
  ];

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}>
      <div className='trazabilidad-home-container'>
        {btns.map((b, idx) =>
          b.userTypeAccess.includes(auth.user?.nombreTipoUsuario!) ? (
            <div className='trazabilidad-home-wrappper' key={idx}>
              <button className='trazabilidad-home-button' onClick={() => router.push(b.path)}>
                {b.icon}
                {b.name}
              </button>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export const TrazabilidadConnected = connect(
  ({ auth }: AppState) => ({ auth }),
  null
)(Trazabilidad);
