export type SimpleObject = { [key: string]: any };
export type SimpleStringObject = { [key: string]: string };
export type CustomObject<T> = { [key: string]: T };

export enum ImageFormats {
  Png = 'png',
  Jpg = 'jpeg'
}

export enum TipoUsuarioValues {
  Operador = 'OPERADOR',
  Admin = 'ADMIN',
  Visualizador = 'VISUALIZADOR',
  Transportista = 'TRANSPORTISTA',
  AdminCobros = 'ADMINISTRADOR COBROS'
}

export enum EstadoOCA {
  ingresada = 'INGRESADA',
  objetadaOrigen = 'OBJETADA ORIGEN',
  objetadaTransportista = 'OBJETADA TRANSPORTISTA',
  enDiscusion = 'EN DISCUSION',
  cobrable = 'COBRABLE',
  cobrada = 'COBRADA',
  cerrada = 'CERRADA',
  expirada = 'EXPIRADA'
}

export const stateRules: {
  [key: string]: {
    auditDateColumn: string;
    normalDays: number; // Normal waiting time
    reopenedDays: number; // Waiting time if reopened
    nextState: EstadoOCA;
  };
} = {
  [EstadoOCA.ingresada]: {
    auditDateColumn: 'fecha_oca_ingresada',
    normalDays: 10,
    reopenedDays: 5,
    nextState: EstadoOCA.expirada
  },
  [EstadoOCA.objetadaOrigen]: {
    auditDateColumn: 'fecha_oca_objetada_origen',
    normalDays: 5,
    reopenedDays: 2,
    nextState: EstadoOCA.cobrable
  },
  [EstadoOCA.objetadaTransportista]: {
    auditDateColumn: 'fecha_oca_objetada_transportista',
    normalDays: 5,
    reopenedDays: 2,
    nextState: EstadoOCA.expirada
  },
  [EstadoOCA.enDiscusion]: {
    auditDateColumn: 'fecha_oca_en_discusion',
    normalDays: 2,
    reopenedDays: 1,
    nextState: EstadoOCA.expirada
  }
};

export enum TipoActivoValues {
  productoTerminado = '1',
  envaseCasillero = '2',
  pallet = '3'
}

export enum IdTipoDocumentoValues {
  idConteoCriticidad = '1',
  idConteoPallet = '2',
  idConteoEnvase = '3'
}

export enum NameConteoValues {
  conteoCriticidad = 'Conteo criticidad',
  conteoEnvases = 'Conteo envases',
  conteoPallet = 'Conteo pallet'
}

export enum QueryNameValues {
  reporteCategoriaConteoMonto = 'reporteCategoriaConteoMonto',
  reporteCategoriaConteoCantidad = 'reporteCategoriaConteoCantidad',
  reporteZonaConetoMonto = 'reporteZonaConetoMonto',
  reporteZonaConetoCantidad = 'reporteZonaConetoCantidad',
  reporteSKUMonto = 'reporteSKUMonto',
  reporteSKUCantidad = 'reporteSKUCantidad',
  reporteVencimientoConteoCantidad = 'reporteVencimientoConteoCantidad',
  reporteVencimientoConteoMonto = 'reporteVencimientoConteoMonto',
  reporteInventarioCriticidad = 'reporteInventarioCriticidad',
  reporteInconsistenciasFecha = 'reporteInconsistenciasFecha'
}

export type QueryNameType = (typeof QueryNameValues)[keyof typeof QueryNameValues];

export enum TipoCobro {
  merma = 'Merma',
  faltante = 'Faltante'
}

export interface BasicEntity {
  _id: string;
}

export interface TipoUsuario extends BasicEntity {
  nombreTipoUsuario: TipoUsuarioValues;
  activo: boolean;
}

export interface User extends BasicEntity {
  activo: boolean;
  nombre: string;
  nombreUsuario: string;
  emailUsuario: string;
  nombreTipoUsuario: TipoUsuarioValues;
  idTipoUsuario: string;
  claveUsuario: string;
  zonas: string[];
}

export interface ZonasOperador extends BasicEntity {
  idUsuario: string;
  nombreUsuario: string;
  nombreTipoUsuario: TipoUsuarioValues;
  zonasAsignadas: any;
}

export interface Activo extends BasicEntity {
  idActivo: string;
  tipoMaterial: string;
  grupoArticulos: string;
  idTipoActivo: string;
  descripcionTipoActivo: string;
}

export interface TipoActivo extends BasicEntity {
  idTipoActivo: string;
  descripcionTipoActivo: string;
}

export interface Nomenclatura extends BasicEntity {
  idNomenclatura: string;
  idTipoActivo: string;
  descripcionTipoActivo: string;
  descripcionNomenclatura: string;
  estado: string;
  agregarCasillero: boolean;
  ordenCargaManual: string;
}

export interface RelacionEnvaseCasillero extends BasicEntity {
  idRelacion: string;
  materialContado: string;
  descripcionMaterialContado: string;
  cantidadContado: string;
  materialRelacion: string;
  descripcionMaterialRelacion: string;
  cantidadRelacion: string;
}

export interface Comentario extends BasicEntity {
  idComentario: string;
  idTipoDocumento: string;
  descripcionTipoDocumento: string;
  comentario: string;
}

export interface MaterialNomenclatura extends BasicEntity {
  idMaterialNomenclatura: string;
  material: string;
  textoBreveMaterial: string;
  idNomenclatura: string;
  nomenclaturasAsignadas: string;
}

export interface Inventario extends BasicEntity {
  id: string;
  cantidadContada: string;
  bodega: string;
  idCentro: string;
  almacen: string;
  material: string;
  textoBreveMaterial: string;
  lote: string;
  fechaRegistro: string;
  fechaProduccion: string;
  fechaVencimiento: string;
  unidadCantidadContada: string;
  tipoStock: string;
  pedidoCliente: string;
  numeroHu: string;
  idDocumento: string;
  nombreUsuario: string;
  descripcion: string;
}

export interface ConteoCriticidadLog extends Inventario {
  idConteoCriticidad: string;
  comentarioLog: string;
  mantenedorIncompleto: string;
  idUsuario: string;
}

export interface InconsistenciaFechaLog extends BasicEntity {
  id: string;
  idConteoCriticidad: string;
  cantidadContada: number;
  idDocumento: string;
  material: string;
  idCentro: string;
  fechaDocumento: string;
  fechaProduccion: string;
  fechaVencimiento: string;
  duracionTotalConservacion: number;
  perfilEnvejecimiento: number;
  descripcionEstadoDocumento: string;
}

export interface Documento extends BasicEntity {
  _id: string;
  idDocumento: string;
  fechaDocumento: string;
  idEstadoDocumento: string;
  fechaCargaDocumento: string;
  nombreUsuario: string;
  descripcionEstadoDocumento: string;
  nombreCentro: string;
}

export interface Centro extends BasicEntity {
  nombreCentro: string;
  cargaPallet: string;
  cargaCo2: string;
  cargaEnvase: string;
}

export interface CentroInventario extends BasicEntity {
  _id: string;
  idCentro: string;
  idEmpresa: string;
  empresa: string;
  zona: string;
  nombreCentro: string;
  zonaDeTransporte: string;
  centroSieteHoras: string;
  bodegaExterna: boolean;
}

export interface EstadoDocumento extends BasicEntity {
  descripcionEstadoDocumento: string;
}

export interface ReporteZonaCategoriaSKU extends BasicEntity {
  orden: number;
  tipoProducto: string;
  grupoProducto: string;
  numeroAntiguoMaterial: string;
  textoBreveMaterial: string;
  zona: string;
  menor_21Dias: string;
  menor_41Dias: string;
  menor_61Dias: string;
  menor_121Dias: string;
  mayor_121Dias: string;
  totalGeneral: string;
}

export interface ReporteVencimiento extends BasicEntity {
  orden: number;
  zona: string;
  numeroAntiguoMaterial: string;
  textoBreveMaterial: string;
  m01: string;
  m02: string;
  m03: string;
  m04: string;
  totalGeneral: string;
}

export interface ReporteInventarioCriticidad extends BasicEntity {
  zona: string;
  nombreCentro: string;
  cpch: string;
  vspt: string;
  ccuChile: string;
  ecomccu: string;
  eccusa: string;
  aguasCcuNestle: string;
}

export interface DiasVenta extends BasicEntity {
  id: string;
  depDist: string;
  grupoProducto: string;
  producto: string;
  marca: string;
  promoVentaMes: string;
  forecast: string;
  porcentajeForecast: string;
}

export interface Sku extends BasicEntity {
  id: string;
  material: string;
  centro: string;
  claseValoracion: string;
  textoBreveMaterial: string;
  ultimaModificacion: string;
  tipoMaterial: string;
  grupoArticulos: string;
  unidadMedidaBase: string;
  grupoCompra: string;
  indicadorAbc: string;
  caracteristicaPlanificacionNec: string;
  categoriaValoracion: string;
  controlPrecio: string;
  precio: string;
  moneda: string;
  cantidadBase: string;
  creadoPor: string;
}

export interface PerfilEnvejecimiento extends BasicEntity {
  id: string;
  material: string;
  numeroAntiguoMaterial: string;
  descripcion: string;
  vence: boolean;
  unidadMedidaBase: string;
  grupoArticulos: string;
  duracionTotalConservacion: string;
}

export interface SkuFactor extends BasicEntity {
  id: string;
  material: string;
  factorTccu: string;
  grupoArticulo: string;
  descripcion: string;
}

export interface Cobro extends BasicEntity {
  cobroId: string;
  idCobroCcu: string;
  numeroDocumentoMaterial: string;
  cartaCobranza: string;
  fechaCobro: string;
  oca: string;
  detalle: string;
  nombreCausal: string;
  evidencia: string;
  nombreTransportista: string;
  patente: string;
  material: string;
  cantidadCajas: string;
  textoBreveMaterial: string;
  nombreCentroOrigen: string;
  nombreCentroDestino: string;
  fechaOca: string;
  fechaOcaIngresada: string;
  fechaOcaObjetadaOrigen: string;
  comentarioObjecionOrigen: string;
  nombreArchivoObjecionOrigen: string;
  fechaOcaObjetadaTransportista: string;
  comentarioObjecionTransportista: string;
  nombreArchivoObjecionTransportista: string;
  comentarioContraObjecionDestino: string;
  fechaOcaCerrada: string;
  comentarioAdministrador: string;
  estadoOcaAnterior: string;
  bp: boolean;
  rutTransportista: boolean;
  centro: boolean;
}

export interface OCA extends BasicEntity {
  oca: string;
  material: string;
  textoBreveMaterial: string;
  cantidadCajas: string;
  nombreTransportista: string;
  patente: string;
  detalle: string;
  evidencia: string;
  estadoOcaActual: string;
  nombreCausal: string;
  action: string;
  nombreTipoDesviacion: TipoCobro;
  fechaOca: string;
  nombreCentroOrigen: string;
  nombreCentroDestino: string;
  timeLeft: string;
  fechaOcaIngresada: string;
  fechaOcaObjetadaOrigen: string;
  nombreArchivoObjecionOrigen: string;
  comentarioObjecionOrigen: string;
  fechaOcaObjetadaTransportista: string;
  comentarioObjecionTransportista: string;
  nombreArchivoObjecionTransportista: string;
  fechaOcaCobrable: string;
  fechaOcaEnDiscusion: string;
  comentarioContraObjecionDestino: string;
  fechaOcaCerrada: string;
  comentarioAdministrador: string;
  estadoOcaAnterior: string;
  ocaReabierta: boolean;
  bp: boolean;
  rutTransportista: boolean;
  centro: boolean;
}

export interface CobroAdmin extends BasicEntity {
  oca: string;
  sku: string;
  descripcionSku: string;
  transporte: string;
  cantidadCajas: string;
  detalleCobro: string;
  evidencia: string;
  estado: string;
  idCobranza: string;
  precioCosto: string;
}

export interface UsuarioTransportista extends BasicEntity {
  nombreTransportista: string;
  idUsuarios: string[];
  nombresUsuarios: string[];
}

export interface Transportista extends BasicEntity {
  transportistaId: string;
  nombreTransportista: string;
  rutTransportista: string;
  bp: string;
  activo: boolean;
  fechaActualizacion: string;
  nombreUsuario: string;
}

export interface TipoCausal extends BasicEntity {
  tipoCausalId: string;
  nombreCausal: string;
}

export interface Dashboard extends BasicEntity {
  oca: string;
  material: string;
  textoBreveMaterial: string;
  precio: string;
  subtotal: number;
  fechaOca: string;
  nombreTransportista: string;
  cantidadCajas: string;
  detalle: string;
  evidencia: string;
  estadoOca: string;
  estadoOcaAnterior: string;
  patente: string;
  nombreCausal: string;
  cobroId: string;
  nombreCentroOrigen: string;
  nombreCentroDestino: string;
}

export interface ItemSociedad extends BasicEntity {
  material: string;
  textoBreveMaterial: string;
  grupoArticulos: string;
  unidadMedidaBase: string;
  nombreSociedad: string;
  tipoSociedad: string;
}

export interface Item extends BasicEntity {
  material: string;
  textoBreveMaterial: string;
  grupoArticulos: string;
  unidadMedidaBase: string;
}

export interface Sociedad extends BasicEntity {
  tipoSociedad: string;
  nombreSociedad: string;
}

export interface EnvasesAndPalletCompliance extends BasicEntity {
  zoneName: string;
  centerName: string;
  [key: string]: string; // dynamically generated columns
}
