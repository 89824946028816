import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../img/ccu_logo.png';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { connect, ConnectedComponent } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { loadUser } from '../actions/authActions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../css/AppHome.css';
import Pages, { Mantenedores, Reportes } from '../pages';
import AppNavbar from './AppNavbar';
import { AppState } from '../store';
import { AuthState } from '../reducers/types';
import Login from './auth/Login';
import GenericContainerSidebar from './generics/GenericContainerSidebar/GenericContainerSidebar';
import Logout from './auth/Logout';
import {
  SkuMaintainer,
  DiasVentaMaintainer,
  PerfilEnvejecimientoMaintainer,
  ZonasOperadoresCRUDMaintainer
} from '../pages/Mantenedores';
import { TipoUsuarioValues } from '../types';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { esES } from '@mui/material/locale';

export type SidebarItem = {
  itemId: string;
  name: string;
  allowedUserTypes: Array<TipoUsuarioValues | undefined>;
  childOrComponent?:
    | Array<SidebarItem>
    | { path: string; component: ConnectedComponent<any, any> | React.ComponentType<{}> };
  hidden?: boolean;
  icon?: string;
  disabled?: boolean;
};

const sideBarItems: Array<SidebarItem> = [
  {
    itemId: '1',
    name: 'Landing',
    hidden: true,
    allowedUserTypes: [],
    childOrComponent: {
      path: '/landing',
      component: Pages.LandingPage
    }
  },
  {
    itemId: '2',
    name: 'Home',
    hidden: true,
    allowedUserTypes: [],
    childOrComponent: {
      path: '/',
      component: Pages.AnonimousPage
    }
  },
  {
    itemId: '3',
    name: 'Conteos',
    allowedUserTypes: [
      TipoUsuarioValues.Admin,
      TipoUsuarioValues.Operador,
      TipoUsuarioValues.Visualizador
    ],
    icon: 'count',
    childOrComponent: [
      {
        itemId: '31',
        name: 'Conteo Criticidad',
        childOrComponent: {
          path: '/conteo-criticidad',
          component: Pages.ConteoCriticidadPage
        },
        allowedUserTypes: [
          TipoUsuarioValues.Admin,
          TipoUsuarioValues.Operador,
          TipoUsuarioValues.Visualizador
        ]
      },
      {
        itemId: '32',
        name: 'Conteo Envases',
        childOrComponent: {
          path: '/conteo-envases',
          component: Pages.ConteoEnvasesPage
        },
        allowedUserTypes: [
          TipoUsuarioValues.Admin,
          TipoUsuarioValues.Operador,
          TipoUsuarioValues.Visualizador
        ]
      },
      {
        itemId: '33',
        name: 'Conteo Pallets',
        childOrComponent: {
          path: '/conteo-pallet',
          component: Pages.ConteoPalletPage
        },
        allowedUserTypes: [
          TipoUsuarioValues.Admin,
          TipoUsuarioValues.Operador,
          TipoUsuarioValues.Visualizador
        ]
      }
    ]
  },
  {
    itemId: '4',
    name: 'Mantenedores',
    allowedUserTypes: [TipoUsuarioValues.Admin],
    icon: 'mantainer',
    childOrComponent: [
      {
        itemId: '4001',
        name: 'Usuarios',
        childOrComponent: {
          path: '/mantenedores/usuarios',
          component: Mantenedores.UsuarioCRUDMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      },
      {
        itemId: '4002',
        name: 'Tipo Usuario',
        childOrComponent: {
          path: '/mantenedores/TipoUsuarios',
          component: Mantenedores.TipoUsuarioCRUDMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      },
      {
        itemId: '4003',
        name: 'Estado Documento',
        childOrComponent: {
          path: '/mantenedores/EstadoDocumentos',
          component: Mantenedores.EstadoDocumentoCRUDMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      },
      {
        itemId: '4004',
        name: 'SKU',
        childOrComponent: {
          path: '/mantenedores/SKU',
          component: SkuMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      },
      {
        itemId: '4005',
        name: 'Item Sociedad',
        childOrComponent: {
          path: '/mantenedores/itemSociedad',
          component: Mantenedores.ItemSociedadCRUDMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      },
      {
        itemId: '4006',
        name: 'Item',
        childOrComponent: {
          path: '/mantenedores/item',
          component: Mantenedores.ItemCRUDMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      },
      {
        itemId: '4007',
        name: 'Sociedad',
        childOrComponent: {
          path: '/mantenedores/sociedad',
          component: Mantenedores.SociedadCRUDMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      },
      {
        itemId: '4008',
        name: 'SKU Factor',
        childOrComponent: {
          path: '/mantenedores/SKUFactor',
          component: Mantenedores.SkuFactorCRUDMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      },
      {
        itemId: '4009',
        name: 'Perfil Envejecimiento',
        childOrComponent: {
          path: '/mantenedores/PerfilEnvejecimiento',
          component: PerfilEnvejecimientoMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      },
      {
        itemId: '4010',
        name: 'Dias Venta',
        childOrComponent: {
          path: '/mantenedores/DiasVentas',
          component: DiasVentaMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      },
      {
        itemId: '4011',
        name: 'Centros',
        childOrComponent: {
          path: '/mantenedores/Centros',
          component: Mantenedores.CentroCRUDMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      },
      {
        itemId: '4012',
        name: 'Centros Inventario',
        childOrComponent: {
          path: '/mantenedores/centros-inventario',
          component: Mantenedores.CentroInventarioCRUDMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      },
      {
        itemId: '4013',
        name: 'Bodegas Usuarios',
        childOrComponent: {
          path: '/mantenedores/BodegasUsuarios',
          component: ZonasOperadoresCRUDMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      },
      {
        itemId: '4014',
        name: 'Activos',
        childOrComponent: {
          path: '/mantenedores/Activos',
          component: Mantenedores.ActivosCRUDMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      },
      {
        itemId: '4015',
        name: 'Tipo Activo',
        childOrComponent: {
          path: '/mantenedores/TipoActivos',
          component: Mantenedores.TipoActivosCRUDMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      },
      {
        itemId: '4016',
        name: 'Nomenclaturas',
        childOrComponent: {
          path: '/mantenedores/Nomenclaturas',
          component: Mantenedores.NomenclaturasCRUDMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      },
      {
        itemId: '4017',
        name: 'Relacion Envases Casillero',
        childOrComponent: {
          path: '/mantenedores/RelacionEnvasesCasillero',
          component: Mantenedores.RelacionEnvaseCasilleroCRUDMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      },
      {
        itemId: '4018',
        name: 'Comentarios',
        childOrComponent: {
          path: '/mantenedores/Comentarios',
          component: Mantenedores.ComentarioMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      },
      {
        itemId: '4019',
        name: 'Relacion SKU Estado',
        childOrComponent: {
          path: '/mantenedores/SkuEstado',
          component: Mantenedores.MaterialNomenclaturaMaintainer
        },
        allowedUserTypes: [TipoUsuarioValues.Admin]
      }
    ]
  },
  {
    itemId: '5',
    name: 'Reportes',
    allowedUserTypes: [
      TipoUsuarioValues.Admin,
      TipoUsuarioValues.Operador,
      TipoUsuarioValues.Visualizador
    ],
    icon: 'report',
    childOrComponent: [
      {
        itemId: '51001',
        name: 'Inconsistencia',
        allowedUserTypes: [
          TipoUsuarioValues.Admin,
          TipoUsuarioValues.Operador,
          TipoUsuarioValues.Visualizador
        ],
        childOrComponent: [
          {
            itemId: '511001',
            name: 'Conteo Criticidad',
            allowedUserTypes: [
              TipoUsuarioValues.Admin,
              TipoUsuarioValues.Operador,
              TipoUsuarioValues.Visualizador
            ],
            childOrComponent: {
              path: '/reportes/incosistencias/conteos-criticidad',
              component: Reportes.conteosCriticidad.ReporteConteoCriticidadLogComponent
            }
          },
          {
            itemId: '511002',
            name: 'Inconsistencia Fecha',
            allowedUserTypes: [
              TipoUsuarioValues.Admin,
              TipoUsuarioValues.Operador,
              TipoUsuarioValues.Visualizador
            ],
            childOrComponent: {
              path: '/reportes/incosistencias/fecha',
              component: Reportes.conteosCriticidad.ReporteInconsistenciaFechaLogComponent
            }
          }
        ]
      },
      {
        itemId: '52001',
        name: 'Generales',
        allowedUserTypes: [
          TipoUsuarioValues.Admin,
          TipoUsuarioValues.Operador,
          TipoUsuarioValues.Visualizador
        ],
        childOrComponent: [
          {
            itemId: '521001',
            name: 'Conteo Monto',
            allowedUserTypes: [
              TipoUsuarioValues.Admin,
              TipoUsuarioValues.Operador,
              TipoUsuarioValues.Visualizador
            ],
            childOrComponent: {
              path: '/reportes/generales/categoria-monto',
              component: Reportes.generales.ReporteCategoriaConteoMontoComponent
            }
          },
          {
            itemId: '521002',
            name: 'Conteo Cantidad',
            allowedUserTypes: [
              TipoUsuarioValues.Admin,
              TipoUsuarioValues.Operador,
              TipoUsuarioValues.Visualizador
            ],
            childOrComponent: {
              path: '/reportes/generales/categoria-conteo-cantidad',
              component: Reportes.generales.ReporteCategoriaConteoCantidadComponent
            }
          },
          {
            itemId: '521003',
            name: 'Zona Conteo Monto',
            allowedUserTypes: [
              TipoUsuarioValues.Admin,
              TipoUsuarioValues.Operador,
              TipoUsuarioValues.Visualizador
            ],
            childOrComponent: {
              path: '/reportes/generales/zona-conteo-monto',
              component: Reportes.generales.ReporteZonaConteoMontoComponent
            }
          },
          {
            itemId: '521004',
            name: 'Zona Conteo Cantidad',
            allowedUserTypes: [
              TipoUsuarioValues.Admin,
              TipoUsuarioValues.Operador,
              TipoUsuarioValues.Visualizador
            ],
            childOrComponent: {
              path: '/reportes/generales/zona-conteo-cantidad',
              component: Reportes.generales.ReporteZonaConteoCantidadComponent
            }
          },
          {
            itemId: '521005',
            name: 'SKU Monto',
            allowedUserTypes: [
              TipoUsuarioValues.Admin,
              TipoUsuarioValues.Operador,
              TipoUsuarioValues.Visualizador
            ],
            childOrComponent: {
              path: '/reportes/generales/sku-monto',
              component: Reportes.generales.ReporteSKUMontoComponent
            }
          },
          {
            itemId: '521006',
            name: 'SKU Cantidad',
            allowedUserTypes: [
              TipoUsuarioValues.Admin,
              TipoUsuarioValues.Operador,
              TipoUsuarioValues.Visualizador
            ],
            childOrComponent: {
              path: '/reportes/generales/sku-cantidad',
              component: Reportes.generales.ReporteSKUCantidadComponent
            }
          },
          {
            itemId: '521007',
            name: 'Vencimiento Monto',
            allowedUserTypes: [
              TipoUsuarioValues.Admin,
              TipoUsuarioValues.Operador,
              TipoUsuarioValues.Visualizador
            ],
            childOrComponent: {
              path: '/reportes/generales/vencimiento-conteo-monto',
              component: Reportes.generales.ReporteVencimientoConteoMontoComponent
            }
          },
          {
            itemId: '521008',
            name: 'Vencimiento Cantidad',
            allowedUserTypes: [
              TipoUsuarioValues.Admin,
              TipoUsuarioValues.Operador,
              TipoUsuarioValues.Visualizador
            ],
            childOrComponent: {
              path: '/reportes/generales/vencimiento-conteo-cantidad',
              component: Reportes.generales.ReporteVencimientoConteoCantidadComponent
            }
          },
          {
            itemId: '521009',
            name: 'Inventario Criticidad',
            allowedUserTypes: [
              TipoUsuarioValues.Admin,
              TipoUsuarioValues.Operador,
              TipoUsuarioValues.Visualizador
            ],
            childOrComponent: {
              path: '/reportes/generales/inventario-criticidad',
              component: Reportes.generales.ReporteInventarioCriticidadComponent
            }
          },
          {
            itemId: '5210010',
            name: 'Cumplimiento Envases y Pallets',
            allowedUserTypes: [
              TipoUsuarioValues.Admin,
              TipoUsuarioValues.Operador,
              TipoUsuarioValues.Visualizador
            ],
            childOrComponent: {
              path: '/reportes/generales/cumplimiento-envases-pallet',
              component: Reportes.generales.EnvasesAndPalletCompliancePage
            }
          }
        ]
      }
    ]
  },
  {
    itemId: '6',
    name: 'Cobros transportistas',
    allowedUserTypes: [
      TipoUsuarioValues.Admin,
      TipoUsuarioValues.AdminCobros,
      TipoUsuarioValues.Operador,
      TipoUsuarioValues.Transportista,
      TipoUsuarioValues.Visualizador
    ],
    icon: 'statistics',
    childOrComponent: {
      path: '/cobros',
      component: Pages.TrazabilidadConnected
    }
  },
  {
    itemId: '7',
    name: 'Cobros admin',
    allowedUserTypes: [
      TipoUsuarioValues.Admin,
      TipoUsuarioValues.AdminCobros,
      TipoUsuarioValues.Visualizador
    ],
    hidden: true,
    childOrComponent: {
      path: '/cobros/admin',
      component: Pages.TrazabilidadAdminConnected
    }
  },
  {
    itemId: '8',
    name: 'Cobros merma',
    allowedUserTypes: [
      TipoUsuarioValues.Admin,
      TipoUsuarioValues.Operador,
      TipoUsuarioValues.AdminCobros,
      TipoUsuarioValues.Operador,
      TipoUsuarioValues.Transportista,
      TipoUsuarioValues.Visualizador
    ],
    hidden: true,
    childOrComponent: {
      path: '/cobros/merma',
      component: Pages.TrazabilidadMermaConnected
    }
  },
  {
    itemId: '9',
    name: 'Cobros faltante',
    allowedUserTypes: [
      TipoUsuarioValues.Admin,
      TipoUsuarioValues.Operador,
      TipoUsuarioValues.AdminCobros,
      TipoUsuarioValues.Operador,
      TipoUsuarioValues.Transportista,
      TipoUsuarioValues.Visualizador
    ],
    hidden: true,
    childOrComponent: {
      path: '/cobros/faltante',
      component: Pages.TrazabilidadFaltanteConnected
    }
  },
  {
    itemId: '10',
    name: 'Dashboard',
    allowedUserTypes: [
      TipoUsuarioValues.Admin,
      TipoUsuarioValues.AdminCobros,
      TipoUsuarioValues.Visualizador
    ],
    hidden: true,
    childOrComponent: {
      path: '/cobros/dashboard',
      component: Pages.TrazabilidadDashboard
    }
  }
];

type Props = {
  loadUser: () => Promise<void>;
  auth: AuthState;
};

const App: FunctionComponent<Props> = ({ auth, loadUser }) => {
  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const allowedRoutes = useMemo(
    () =>
      extractPathAndComponent(sideBarItems).filter(
        (item) =>
          item.allowedUserTypes.includes(auth.user?.nombreTipoUsuario) ||
          item.allowedUserTypes.length === 0 // all user allow
      ),
    [auth]
  );

  const allowedSidebarItems = useMemo(
    () =>
      auth.user?.nombreTipoUsuario
        ? filterHiddenItems(sideBarItems, auth.user?.nombreTipoUsuario)
        : [],
    [auth]
  );

  const theme = useTheme();

  const linkTopNav = useMemo(
    () =>
      [TipoUsuarioValues.AdminCobros, TipoUsuarioValues.Transportista].includes(
        auth.user?.nombreTipoUsuario!
      )
        ? '/cobros'
        : '/conteo-criticidad',
    [auth.user?.nombreTipoUsuario]
  );

  /*
    NOTE: Only use for debugging purpose
   Error: 'Cannot remove a child from a different parent'

   https://github.com/facebook/react/issues/11538
   https://www.npmjs.com/package/remove-child-node-error-debugger
  
  startDebugger();
  */

  return (
    <div className='App'>
      <ToastContainer theme='colored' />
      <Helmet>
        <meta name={'X-App-Version'} content={`${process.env.REACT_APP_VERSION}`} />
      </Helmet>

      <ThemeProvider theme={createTheme(theme, esES)}>
        <Router>
          {auth.isAuthenticated ? (
            <GenericContainerSidebar
              img={logo}
              AppNavbar={<AppNavbar sideBarItems={allowedSidebarItems} />}
              linkTopNav={linkTopNav}
              logout={<Logout />}
              user={auth.user?.nombreUsuario}>
              <Switch>
                {allowedRoutes.map(({ path, component }, idx) => (
                  <Route key={`main_menu_${idx}`} exact path={path} component={component} />
                ))}
                <Route path='/404' component={Pages.NotFoundPage} />
                <Redirect to='/404' />
              </Switch>
            </GenericContainerSidebar>
          ) : (
            <Login />
          )}
        </Router>
      </ThemeProvider>
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }), { loadUser })(App);

const extractPathAndComponent = (
  items: Array<SidebarItem>
): Array<{
  path: string;
  component: ConnectedComponent<any, any> | React.ComponentType<{}>;
  allowedUserTypes: Array<TipoUsuarioValues | undefined>;
}> => {
  let result: Array<{
    path: string;
    component: ConnectedComponent<any, any> | React.ComponentType<{}>;
    allowedUserTypes: Array<TipoUsuarioValues | undefined>;
  }> = [];

  items.forEach((item) => {
    if (item.childOrComponent) {
      if (Array.isArray(item.childOrComponent)) {
        result = result.concat(extractPathAndComponent(item.childOrComponent));
      } else if ('path' in item.childOrComponent && 'component' in item.childOrComponent) {
        result.push({
          path: item.childOrComponent.path,
          component: item.childOrComponent.component,
          allowedUserTypes: item.allowedUserTypes
        });
      }
    }
  });

  return result;
};

const filterHiddenItems = (
  items: Array<SidebarItem>,
  userType: TipoUsuarioValues
): Array<SidebarItem> => {
  return items.reduce<Array<SidebarItem>>((filteredItems, item) => {
    if (item.hidden || !item.allowedUserTypes.includes(userType)) {
      return filteredItems;
    }

    let filteredChildOrComponent;
    if (Array.isArray(item.childOrComponent)) {
      filteredChildOrComponent = filterHiddenItems(item.childOrComponent, userType);
    } else {
      filteredChildOrComponent = item.childOrComponent;
    }

    if (Array.isArray(filteredChildOrComponent) && filteredChildOrComponent.length === 0) {
      filteredChildOrComponent = undefined;
    }

    filteredItems.push({
      ...item,
      childOrComponent: filteredChildOrComponent
    });

    return filteredItems;
  }, []);
};
