import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import EnvasesAndPalletComplianceEntity from '../new-table/entities/EnvasesAndPalletComplianceEntity';
import { EnvasesAndPalletComplianceCRUD } from '../new-table/components/GeneratedComponents';
import { ExtendedColumnDef, WebEntity } from '../new-table/entities/types';
import { connect, ConnectedComponent } from 'react-redux';
import { EnvasesAndPalletCompliance, SimpleObject } from '../types';
import { getUncatchEndpointData } from '../utils/request';
import { Alert, Badge, Button, Chip, IconButton, Modal, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar, PickersDay } from '@mui/x-date-pickers';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { formatStringNumber } from '../utils/utils';
import { snakeToCamelCase } from '../utils/convert';
import moment from 'moment';
import ReporteCumplimientoExport, {
  CentroExport,
  DataExport,
  zonasExport
} from '../components/ReporteCumplimientoExport';
import { AppState } from '../store';
import { EntitiesState } from '../reducers/types';
import '../css/envasesAndPalletCompliancePage.css';
import 'moment/locale/es';

moment.updateLocale('es', {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
});

const assetTypes = [
  {
    label: 'Pallets',
    key: 'pallet'
  },
  {
    label: 'CO2',
    key: 'co2'
  },
  {
    label: 'Envases',
    key: 'envase'
  }
];

interface DropdownProps extends DropdownItemProps {
  value: string | number;
  text: string;
}

const EnvasesAndPalletCompliancePage: FunctionComponent<{ entities: EntitiesState }> = ({
  entities
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dates, setDates] = useState<Array<moment.Moment>>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [dataToExport, setDataToExport] = useState<DataExport[]>([]);

  const [categoriesList, setCategoriesList] = useState<Array<DropdownProps>>([]);
  const [assetStatusList, setAssetStatusList] = useState<Array<DropdownProps>>([]);
  const [selectedCategories, setSelectedCategories] = useState<Array<DropdownProps>>([]);
  const [selectedAssetStatus, setSelectedAssetStatus] = useState<Array<DropdownProps>>([]);

  useEffect(() => {
    const inputData = entities.envasesAndPalletCompliance.list;
    const fechas = new Set<string>();

    inputData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        const match = key.match(/_(\d{8})$/);
        if (match) fechas.add(match[1]);
      });
    });

    inputData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        const match = key.match(/_(\d{8})$/);
        if (match) fechas.add(match[1]);
      });
    });

    const fechasOrdenadas = Array.from(fechas).sort();

    const data: DataExport[] = fechasOrdenadas.map((fechaStr) => {
      const fecha = new Date(
        parseInt(fechaStr.substring(0, 4)), // year
        parseInt(fechaStr.substring(4, 6)) - 1, // month (0-based)
        parseInt(fechaStr.substring(6, 8)) // day
      );

      const zonas: Record<string, CentroExport[]> = {
        norteGrande: [],
        norteChico: [],
        centro: [],
        centroSur: [],
        sur: []
      };

      inputData.forEach((item) => {
        const centro: CentroExport = {
          centro: item.centerName,
          pallet: {
            valor: !isNaN(Number(item[`pallet_${fechaStr}`]))
              ? Number(item[`pallet_${fechaStr}`])
              : 0,
            carga: [true, 'true', 'TRUE'].includes(item[`cargaPallet_${fechaStr}`]) ? true : false
          },
          co2: {
            valor: !isNaN(Number(item[`co2_${fechaStr}`])) ? Number(item[`co2_${fechaStr}`]) : 0,
            carga: [true, 'true', 'TRUE'].includes(item[`cargaCo2_${fechaStr}`]) ? true : false
          },
          envase: {
            valor: !isNaN(Number(item[`envase_${fechaStr}`]))
              ? Number(item[`envase_${fechaStr}`])
              : 0,
            carga: [true, 'true', 'TRUE'].includes(item[`cargaEnvase_${fechaStr}`]) ? true : false
          }
        };

        const zona = item.zoneName;
        const zonaKey = zonasExport.find((z) => z.name === zona)?.key;
        if (zonaKey && zonas[zonaKey]) {
          zonas[zonaKey].push(centro);
        }
      });

      return {
        fecha,
        norteGrande: zonas.norteGrande,
        norteChico: zonas.norteChico,
        centro: zonas.centro,
        centroSur: zonas.centroSur,
        sur: zonas.sur
      };
    });

    setDataToExport(data);
  }, [entities.envasesAndPalletCompliance.list]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const removeDateSelector = (index: number) => {
    setDates((prevDates) => prevDates.filter((_, i) => i !== index));
  };

  const handleDateChange = (value: moment.Moment | null) => {
    if (value) {
      setDates((prevDates) => {
        const formattedValue = value.format('DD-MM-YYYY');
        const isAlreadySelected = prevDates.some(
          (date) => date.format('DD-MM-YYYY') === formattedValue
        );

        return isAlreadySelected
          ? prevDates.filter((date) => date.format('DD-MM-YYYY') !== formattedValue)
          : prevDates.length < 3
          ? [...prevDates, value]
          : prevDates;
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      const categories = await getUncatchEndpointData({
        endpoint: 'reportes/envasesAndPalletCompliance/categories'
      });
      if (categories && categories.length > 0) {
        const data: DropdownProps[] = categories.map((row: SimpleObject, idx: number) => ({
          key: `indicator_${idx}`,
          value: row.categoriesId,
          text: row.categoriesName
        }));
        setCategoriesList(data);
      }
    })();

    (async () => {
      const categories = await getUncatchEndpointData({
        endpoint: 'reportes/envasesAndPalletCompliance/assetStatus'
      });
      if (categories && categories.length > 0) {
        const data: DropdownProps[] = categories.map((row: SimpleObject, idx: number) => ({
          key: `indicator_${idx}`,
          value: row.assetStatusId,
          text: row.assetStatusName
        }));
        setAssetStatusList(data);
      }
    })();
    setLoading(false);
  }, []);

  useEffect(() => {
    const today = moment();
    const previousDays = [
      today.clone().subtract(2, 'days'),
      today.clone().subtract(1, 'days'),
      today
    ];

    setDates(previousDays);
  }, []);

  const CRUD: ConnectedComponent<any, any> = useMemo(() => {
    const tableColumns: ExtendedColumnDef<EnvasesAndPalletCompliance>[] = [
      {
        header: 'Zona',
        accessorKey: 'zoneName',
        columnType: 'text'
      },
      {
        header: 'Centro',
        accessorKey: 'centerName',
        columnType: 'text'
      }
    ];

    dates.forEach((d) => {
      assetTypes.forEach((a) => {
        const header = `${d.format('DD-MMM')} ${a.label}`;
        const accessorKey = `${a.key}_${d.format('YYYYMMDD')}`;

        const cargaAccesorKey = snakeToCamelCase(`carga_${accessorKey}`);

        tableColumns.push({
          header,
          accessorKey,
          columnType: 'numeric',
          accessorFn: (row) => row[accessorKey],
          Cell: ({ row }) => (
            <div
              style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: '10px' }}>
              {row.original[accessorKey] ? formatStringNumber(row.original[accessorKey]) : 0}

              {row.original[cargaAccesorKey] === true ? (
                <div className='envases-and-pallet-compliance-green-dot'></div>
              ) : row.original[cargaAccesorKey] === false ? (
                <div className='envases-and-pallet-compliance-red-dot'></div>
              ) : (
                ''
              )}
            </div>
          ),
          aggregationFn: 'sum',
          AggregatedCell: ({ cell }) => {
            const sum = cell.getValue();
            return (
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Stack sx={{ fontWeight: 'bold' }}>
                  {sum ? formatStringNumber(sum?.toString(), 0, 2) : 0}
                </Stack>
              </div>
            );
          },
          Footer: ({ table }: { table: any }) => {
            const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
            const total = rows.reduce(
              (acc: number, obj: EnvasesAndPalletCompliance) =>
                obj[accessorKey] ? acc + Number(obj[accessorKey]) : acc,
              0
            );
            return (
              <div style={{ display: 'flex', gap: 10, width: '100%', justifyContent: 'flex-end' }}>
                <Stack sx={{ fontSize: 15, color: 'green' }}>
                  {formatStringNumber(total.toString(), 0, 0)}
                </Stack>
              </div>
            );
          }
        });
      });
    });

    const entity: WebEntity<EnvasesAndPalletCompliance> = {
      ...EnvasesAndPalletComplianceEntity,
      tableColumns
    };

    const days = dates.map((d) => d.format('YYYYMMDD'));
    const categories = selectedCategories.map((obj) => obj.value);
    const assetStatus = selectedAssetStatus.map((obj) => obj.value);

    return EnvasesAndPalletComplianceCRUD(entity, { days, categories, assetStatus });
  }, [dates, selectedCategories, selectedAssetStatus]);

  return (
    <div className='envases-and-pallet-compliance-container'>
      <div className='envases-and-pallet-compliance-filters-section'>
        <div className='envases-and-pallet-compliance-filters-container'>
          <Dropdown
            options={categoriesList}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = categoriesList.filter((item) => value.includes(item.value));
              setSelectedCategories(updatedSelected);
            }}
            value={selectedCategories.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Categorias'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />

          <Dropdown
            options={assetStatusList}
            onChange={(_, data) => {
              const value = data.value as Array<string | number>;
              const updatedSelected = assetStatusList.filter((item) => value.includes(item.value));
              setSelectedAssetStatus(updatedSelected);
            }}
            value={selectedAssetStatus.map((item) => item.value)}
            loading={loading}
            disabled={loading}
            className='mb-3'
            placeholder='Estados'
            fluid
            lazyLoad
            search
            selection
            multiple
            clearable
          />
        </div>

        <Button onClick={handleOpen} variant='contained'>
          <CalendarMonthIcon />
          Fechas
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <div className='envases-and-pallet-compliance-date-modal-container'>
            <div className='envases-and-pallet-compliance-date-modal-header-container'>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            {dates.length === 3 && <Alert severity='info'>Solo puedes seleccionar 3 dias</Alert>}

            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='es'>
              <DateCalendar
                onChange={handleDateChange}
                sx={{
                  [`.Mui-selected`]: {
                    backgroundColor: '#fff',
                    color: 'rgba(0, 0, 0, 0.87)'
                  },
                  [`.MuiPickersDay-today`]: {
                    border: 'none'
                  }
                }}
                slots={{
                  day: (props) => {
                    const isSelected =
                      !props.outsideCurrentMonth &&
                      dates
                        .map((d) => d.format('DD-MM-YYYY'))
                        .includes((props.day as moment.Moment).format('DD-MM-YYYY'));

                    return (
                      <Badge
                        overlap='circular'
                        color='error'
                        variant={isSelected ? 'dot' : undefined}
                        badgeContent={isSelected ? ' ' : undefined}>
                        <PickersDay
                          {...props}
                          sx={{
                            [`.Mui-selected`]: {
                              backgroundColor: 'red'
                            }
                          }}
                        />
                      </Badge>
                    );
                  }
                }}
              />
            </LocalizationProvider>

            <div className='envases-and-pallet-compliance-date-modal-chip-container'>
              {dates
                .sort((a, b) => (a.isBefore(b) ? -1 : 1))
                .map((d, idx) => (
                  <Chip
                    key={`envases_and_pallet_compliance_chip_key_${idx}`}
                    label={d?.format('DD-MM-YYYY')}
                    onDelete={() => removeDateSelector(idx)}
                  />
                ))}
            </div>
          </div>
        </Modal>

        <ReporteCumplimientoExport data={dataToExport} />
      </div>

      <CRUD />
    </div>
  );
};

export default connect(
  ({ entities }: AppState) => ({ entities }),
  null
)(EnvasesAndPalletCompliancePage);
