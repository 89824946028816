import React, { Button } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { BorderType, utils, writeFile } from 'xlsx-js-style';
import { dateXLSXFormatter } from '../utils/export';
import { SimpleObject } from '../types';
import Swal from 'sweetalert2';

type ValorCentro = {
  valor: number;
  carga: boolean;
};

export type CentroExport = {
  centro: string;
  pallet: ValorCentro;
  co2: ValorCentro;
  envase: ValorCentro;
};

export type DataExport = {
  fecha: Date;
  norteGrande: CentroExport[];
  norteChico: CentroExport[];
  centro: CentroExport[];
  centroSur: CentroExport[];
  sur: CentroExport[];
};

export const zonasExport: Array<{ name: string; key: keyof Omit<DataExport, 'fecha'> }> = [
  { name: 'NORTE GRANDE', key: 'norteGrande' },
  { name: 'NORTE CHICO', key: 'norteChico' },
  { name: 'CENTRO', key: 'centro' },
  { name: 'CENTRO SUR', key: 'centroSur' },
  { name: 'SUR', key: 'sur' }
];

const ReporteCumplimientoExport: FunctionComponent<{ data: DataExport[] }> = ({ data }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleExport = () => {
    if (data.length === 0) {
      Swal.fire({
        title: 'No se encontró información a exportar',
        icon: 'warning'
      });
      return;
    }

    setLoading(true);

    setTimeout(() => {
      const wsData: any = [[]];
      const merges: any[] = [];

      const fechas = data.map((d) => d.fecha);

      // rows
      const datesRow = 1;
      const tableHeadersRow = 2;
      let currentRowData = 3;

      //columns
      const zonaCol = 1;
      const centroCol = 2;
      let colDataOffset = 3;

      const setHiddenRows = new Set();

      wsData[tableHeadersRow] = [
        {},
        {
          v: 'Zona',
          s: { ...greenStyle_1, border: borderStyle('medium', 'medium', 'medium', 'medium') }
        },
        {
          v: 'Centro',
          s: { ...greenStyle_1, border: borderStyle('medium', 'medium', 'medium', 'medium') }
        }
      ];

      fechas.forEach((f) => {
        for (let i = 0; i < 3; i++) {
          wsData[datesRow] = wsData[datesRow] || [];
          wsData[datesRow][colDataOffset + i] = {
            v: i === 0 ? dateXLSXFormatter(f, 'date') : '',
            t: i === 0 ? 'd' : undefined,
            s: {
              ...greenStyle_1,
              border: borderStyle('medium', 'medium', 'medium', 'medium'),
              alignment: { horizontal: 'center', vertical: 'center' }
            }
          };
        }

        wsData[tableHeadersRow] = [
          ...wsData[tableHeadersRow],
          {
            v: 'Pallets',
            s: {
              ...greenStyle_1,
              border: borderStyle('medium', 'medium', 'medium'),
              alignment: { horizontal: 'center', vertical: 'center' }
            }
          },
          {
            v: 'CO2',
            s: {
              ...greenStyle_1,
              border: borderStyle('medium', 'medium'),
              alignment: { horizontal: 'center', vertical: 'center' }
            }
          },
          {
            v: 'Envases',
            s: {
              ...greenStyle_1,
              border: borderStyle('medium', 'medium', undefined, 'medium'),
              alignment: { horizontal: 'center', vertical: 'center' }
            }
          }
        ];

        merges.push({
          s: { r: datesRow, c: colDataOffset },
          e: { r: datesRow, c: colDataOffset + 2 }
        });

        colDataOffset += 3;
      });

      const centroRowMap: Record<string, number> = {};

      let totalGeneral: { [key: string]: { pallet: string[]; co2: string[]; envase: string[] } } =
        {};
      let cantidadEsperada: {
        [key: string]: { pallet: string[]; co2: string[]; envase: string[] };
      } = {};
      let cantidadObtenida: {
        [key: string]: { pallet: string[]; co2: string[]; envase: string[] };
      } = {};

      zonasExport.forEach((zonaKey) => {
        let totalZona: { [key: string]: { pallet: string[]; co2: string[]; envase: string[] } } =
          {};
        let hasData = false;

        data.forEach((fechaData) => {
          if (fechaData[zonaKey.key].length > 0) {
            hasData = true;
          }

          fechaData[zonaKey.key].forEach((centro) => {
            let rowIndex = centroRowMap[centro.centro];
            if (rowIndex === undefined) {
              rowIndex = currentRowData;
              centroRowMap[centro.centro] = currentRowData;
              currentRowData++;
            }
            wsData[rowIndex] = wsData[rowIndex] || [];
            wsData[rowIndex][zonaCol] = {
              v: zonaKey.name,
              s: {
                ...greenStyle_2,
                border: borderStyle(undefined, undefined, 'medium', 'medium'),
                alignment: { horizontal: 'left', vertical: 'center' }
              }
            };

            wsData[rowIndex][centroCol] = {
              v: centro.centro,
              s: {
                ...greenStyle_2,
                border: borderStyle(undefined, undefined, 'medium', 'medium'),
                alignment: { horizontal: 'left', vertical: 'center' }
              }
            };

            let colOffset = 3;
            data.forEach((fecha) => {
              const centroData = fecha[zonaKey.key].find((c) => c.centro === centro.centro);
              const pallet = centroData?.pallet.valor || 0;
              const cargaPallet = centroData?.pallet.carga || false;
              const co2 = centroData?.co2.valor || 0;
              const cargaCo2 = centroData?.co2.carga || false;
              const envase = centroData?.envase.valor || 0;
              const cargaEnvase = centroData?.envase.carga || false;

              wsData[rowIndex][colOffset] = formatNumberCell(pallet, {
                border: borderStyle(undefined, undefined, 'medium', 'medium'),
                alignment: { horizontal: 'right', vertical: 'center' },
                fill: !cargaPallet ? { fgColor: { rgb: 'FF3300' } } : undefined
              });
              wsData[rowIndex][colOffset + 1] = formatNumberCell(co2, {
                border: borderStyle(undefined, undefined, 'medium', 'medium'),
                alignment: { horizontal: 'right', vertical: 'center' },
                fill: !cargaCo2 ? { fgColor: { rgb: 'FF3300' } } : undefined
              });
              wsData[rowIndex][colOffset + 2] = formatNumberCell(envase, {
                border: borderStyle(undefined, undefined, 'medium', 'medium'),
                alignment: { horizontal: 'right', vertical: 'center' },
                fill: !cargaEnvase ? { fgColor: { rgb: 'FF3300' } } : undefined
              });

              if (!totalZona[fecha.fecha.toISOString()]) {
                totalZona[fecha.fecha.toISOString()] = { pallet: [], co2: [], envase: [] };
              }
              if (
                !totalZona[fecha.fecha.toISOString()].pallet.includes(
                  getExcelCell(rowIndex, colOffset)
                )
              )
                totalZona[fecha.fecha.toISOString()].pallet.push(getExcelCell(rowIndex, colOffset));

              if (
                !totalZona[fecha.fecha.toISOString()].co2.includes(
                  getExcelCell(rowIndex, colOffset + 1)
                )
              )
                totalZona[fecha.fecha.toISOString()].co2.push(
                  getExcelCell(rowIndex, colOffset + 1)
                );
              if (
                !totalZona[fecha.fecha.toISOString()].envase.includes(
                  getExcelCell(rowIndex, colOffset + 2)
                )
              )
                totalZona[fecha.fecha.toISOString()].envase.push(
                  getExcelCell(rowIndex, colOffset + 2)
                );

              if (!cantidadEsperada[fecha.fecha.toISOString()]) {
                cantidadEsperada[fecha.fecha.toISOString()] = { pallet: [], co2: [], envase: [] };
              }

              if (!cantidadObtenida[fecha.fecha.toISOString()]) {
                cantidadObtenida[fecha.fecha.toISOString()] = { pallet: [], co2: [], envase: [] };
              }

              if (
                (pallet > 0 || !cargaPallet) &&
                !cantidadEsperada[fecha.fecha.toISOString()].pallet.includes(
                  getExcelCell(rowIndex, colOffset)
                )
              )
                cantidadEsperada[fecha.fecha.toISOString()].pallet.push(
                  getExcelCell(rowIndex, colOffset)
                );
              if (
                (co2 > 0 || !cargaCo2) &&
                !cantidadEsperada[fecha.fecha.toISOString()].co2.includes(
                  getExcelCell(rowIndex, colOffset + 1)
                )
              )
                cantidadEsperada[fecha.fecha.toISOString()].co2.push(
                  getExcelCell(rowIndex, colOffset + 1)
                );
              if (
                (envase > 0 || !cargaEnvase) &&
                !cantidadEsperada[fecha.fecha.toISOString()].envase.includes(
                  getExcelCell(rowIndex, colOffset + 2)
                )
              )
                cantidadEsperada[fecha.fecha.toISOString()].envase.push(
                  getExcelCell(rowIndex, colOffset + 2)
                );

              if (
                cargaPallet &&
                cantidadEsperada[fecha.fecha.toISOString()].pallet.includes(
                  getExcelCell(rowIndex, colOffset)
                ) &&
                !cantidadObtenida[fecha.fecha.toISOString()].pallet.includes(
                  getExcelCell(rowIndex, colOffset)
                )
              )
                cantidadObtenida[fecha.fecha.toISOString()].pallet.push(
                  getExcelCell(rowIndex, colOffset)
                );
              if (
                cargaCo2 &&
                cantidadEsperada[fecha.fecha.toISOString()].co2.includes(
                  getExcelCell(rowIndex, colOffset + 1)
                ) &&
                !cantidadObtenida[fecha.fecha.toISOString()].co2.includes(
                  getExcelCell(rowIndex, colOffset + 1)
                )
              )
                cantidadObtenida[fecha.fecha.toISOString()].co2.push(
                  getExcelCell(rowIndex, colOffset + 1)
                );
              if (
                cargaEnvase &&
                cantidadEsperada[fecha.fecha.toISOString()].envase.includes(
                  getExcelCell(rowIndex, colOffset + 2)
                ) &&
                !cantidadObtenida[fecha.fecha.toISOString()].envase.includes(
                  getExcelCell(rowIndex, colOffset + 2)
                )
              )
                cantidadObtenida[fecha.fecha.toISOString()].envase.push(
                  getExcelCell(rowIndex, colOffset + 2)
                );

              colOffset += 3;
            });
          });
        });

        if (hasData) {
          wsData[currentRowData] = [];
          wsData[currentRowData][zonaCol] = {
            v: `Total ${zonaKey.name}`,
            s: {
              ...greenStyle_1,
              border: borderStyle(undefined, undefined, 'medium', 'medium')
            }
          };
          wsData[currentRowData][centroCol] = {
            v: '',
            s: {
              ...greenStyle_1,
              border: borderStyle(undefined, undefined, 'medium', 'medium')
            }
          };

          let colOffset = 3;
          data.forEach((fecha) => {
            const totalFecha = totalZona[fecha.fecha.toISOString()] || {
              pallet: [],
              co2: [],
              envase: []
            };

            if (!totalGeneral[fecha.fecha.toISOString()]) {
              totalGeneral[fecha.fecha.toISOString()] = { pallet: [], co2: [], envase: [] };
            }

            totalGeneral[fecha.fecha.toISOString()].pallet.push(
              getExcelCell(currentRowData, colOffset)
            );
            totalGeneral[fecha.fecha.toISOString()].co2.push(
              getExcelCell(currentRowData, colOffset + 1)
            );
            totalGeneral[fecha.fecha.toISOString()].envase.push(
              getExcelCell(currentRowData, colOffset + 2)
            );

            wsData[currentRowData][colOffset] = formatFormulaCell('SUM', totalFecha.pallet, {
              ...greenStyle_1,
              border: borderStyle(undefined, undefined, 'medium', 'medium'),
              alignment: { horizontal: 'right', vertical: 'center' }
            });
            wsData[currentRowData][colOffset + 1] = formatFormulaCell('SUM', totalFecha.co2, {
              ...greenStyle_1,
              border: borderStyle(undefined, undefined, 'medium', 'medium'),
              alignment: { horizontal: 'right', vertical: 'center' }
            });
            wsData[currentRowData][colOffset + 2] = formatFormulaCell('SUM', totalFecha.envase, {
              ...greenStyle_1,
              border: borderStyle(undefined, undefined, 'medium', 'medium'),
              alignment: { horizontal: 'right', vertical: 'center' }
            });

            colOffset += 3;
          });

          currentRowData++;
        }
      });

      wsData[currentRowData] = [];
      wsData[currentRowData + 1] = [];
      wsData[currentRowData + 2] = [];
      wsData[currentRowData + 3] = [];
      wsData[currentRowData][zonaCol] = {
        v: 'Total General',
        s: {
          ...greenStyle_1,
          border: borderStyle('thick', 'thick', 'thick'),
          alignment: { horizontal: 'center', vertical: 'center' }
        }
      };
      wsData[currentRowData][centroCol] = {
        v: '',
        s: {
          ...greenStyle_1,
          border: borderStyle('thick', 'thick', undefined, 'thick'),
          alignment: { horizontal: 'center', vertical: 'center' }
        }
      };

      wsData[currentRowData + 3][zonaCol] = {
        v: '% Cumplimiento',
        s: {
          font: { bold: true, italic: true }
        }
      };

      let colOffset = 3;
      data.forEach((fecha, idx) => {
        const totalFecha = totalGeneral[fecha.fecha.toISOString()] || {
          pallet: [],
          co2: [],
          envase: []
        };
        const totalEsperada = cantidadEsperada[fecha.fecha.toISOString()] || {
          pallet: [],
          co2: [],
          envase: []
        };
        const totalObtenida = cantidadObtenida[fecha.fecha.toISOString()] || {
          pallet: [],
          co2: [],
          envase: []
        };

        wsData[currentRowData][colOffset] = formatFormulaCell('SUM', totalFecha.pallet, {
          ...greenStyle_1,
          border: borderStyle('thick', 'thick'),
          alignment: { horizontal: 'right', vertical: 'center' }
        });
        wsData[currentRowData + 1][colOffset] = formatFormulaCell('COUNT', totalEsperada.pallet, {
          alignment: { horizontal: 'right', vertical: 'center' }
        });
        wsData[currentRowData + 2][colOffset] = formatFormulaCell('COUNT', totalObtenida.pallet, {
          alignment: { horizontal: 'right', vertical: 'center' }
        });
        wsData[currentRowData + 3][colOffset] = {
          f: `IFERROR(${getExcelCell(currentRowData + 2, colOffset)}/${getExcelCell(
            currentRowData + 1,
            colOffset
          )},0)`,
          t: 'n',
          z: '#,##0%',
          s: {
            alignment: { horizontal: 'right', vertical: 'center' },
            font: { italic: true }
          }
        };

        wsData[currentRowData][colOffset + 1] = formatFormulaCell('SUM', totalFecha.co2, {
          ...greenStyle_1,
          border: borderStyle('thick', 'thick'),
          alignment: { horizontal: 'right', vertical: 'center' }
        });
        wsData[currentRowData + 1][colOffset + 1] = formatFormulaCell('COUNT', totalEsperada.co2, {
          alignment: { horizontal: 'right', vertical: 'center' }
        });
        wsData[currentRowData + 2][colOffset + 1] = formatFormulaCell('COUNT', totalObtenida.co2, {
          alignment: { horizontal: 'right', vertical: 'center' }
        });
        wsData[currentRowData + 3][colOffset + 1] = {
          f: `IFERROR(${getExcelCell(currentRowData + 2, colOffset + 1)}/${getExcelCell(
            currentRowData + 1,
            colOffset + 1
          )},0)`,
          t: 'n',
          z: '#,##0%',
          s: {
            alignment: { horizontal: 'right', vertical: 'center' },
            font: { italic: true }
          }
        };

        wsData[currentRowData][colOffset + 2] = formatFormulaCell('SUM', totalFecha.envase, {
          ...greenStyle_1,
          border: borderStyle(
            'thick',
            'thick',
            undefined,
            data.length === idx + 1 ? 'thick' : undefined
          ),
          alignment: { horizontal: 'right', vertical: 'center' }
        });
        wsData[currentRowData + 1][colOffset + 2] = formatFormulaCell(
          'COUNT',
          totalEsperada.envase,
          {
            alignment: { horizontal: 'right', vertical: 'center' }
          }
        );
        setHiddenRows.add(currentRowData + 1);
        wsData[currentRowData + 2][colOffset + 2] = formatFormulaCell(
          'COUNT',
          totalObtenida.envase,
          {
            alignment: { horizontal: 'right', vertical: 'center' }
          }
        );
        setHiddenRows.add(currentRowData + 2);
        wsData[currentRowData + 3][colOffset + 2] = {
          f: `IFERROR(${getExcelCell(currentRowData + 2, colOffset + 2)}/${getExcelCell(
            currentRowData + 1,
            colOffset + 2
          )},0)`,
          t: 'n',
          z: '#,##0%',
          s: {
            alignment: { horizontal: 'right', vertical: 'center' },
            font: { italic: true }
          }
        };

        colOffset += 3;
      });

      currentRowData++;

      const ws = utils.aoa_to_sheet(wsData);
      ws['!merges'] = merges;
      ws['!cols'] = [{ wch: 10 }, { wch: 20 }, { wch: 20 }];

      const arrayHiddenRows = Array.from(setHiddenRows) as number[];
      const lastHiddenRow = Math.max(...arrayHiddenRows);

      ws['!rows'] = Array.from({ length: lastHiddenRow + 1 }, (_, i) => i).map((rowNumber) => {
        if (arrayHiddenRows.includes(rowNumber)) {
          return { hidden: true };
        }
        return { hidden: false };
      });

      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Datos');
      writeFile(wb, 'cumplimiento-envases-pallet.xlsx');
      setLoading(false);
    }, 500);
  };

  return (
    <Button
      onClick={handleExport}
      variant='contained'
      color='success'
      disabled={loading || data.length === 0}>
      <FileDownloadOutlinedIcon />
      Exportar Reporte
    </Button>
  );
};

export default ReporteCumplimientoExport;

const formatFormulaCell = (type: 'SUM' | 'COUNT', cells: string[], style: SimpleObject) => {
  if (cells.length === 0) {
    return {
      v: 0,
      t: 'n',
      z: '#,##0',
      s: style
    };
  }
  return {
    f: `${type}(${cells.join(',')})`,
    t: 'n',
    z: '#,##0',
    s: style
  };
};

const formatNumberCell = (value: number, style: SimpleObject) => ({
  v: value,
  t: 'n',
  z: '#,##0',
  s: style
});

const greenStyle_1 = {
  fill: { fgColor: { rgb: 'C4D79B' } },
  font: { bold: true }
};

const greenStyle_2 = {
  fill: { fgColor: { rgb: 'D8E4BC' } },
  font: { bold: true }
};

const borderStyle = (
  top?: BorderType,
  bottom?: BorderType,
  left?: BorderType,
  right?: BorderType
) => ({
  top: top ? { style: top, color: { rgb: '000000' } } : undefined,
  bottom: bottom ? { style: bottom, color: { rgb: '000000' } } : undefined,
  left: left ? { style: left, color: { rgb: '000000' } } : undefined,
  right: right ? { style: right, color: { rgb: '000000' } } : undefined
});

const getExcelCell = (row: number, col: number): string => {
  let columnLabel = '';
  let colNumber = col + 1;

  while (colNumber > 0) {
    colNumber--;
    columnLabel = String.fromCharCode((colNumber % 26) + 65) + columnLabel;
    colNumber = Math.floor(colNumber / 26);
  }

  return `${columnLabel}${row + 1}`;
};
