import { EnvasesAndPalletCompliance } from '../../types';
import { ExtendedColumnDef, WebEntity } from './types';

const EnvasesAndPalletComplianceEntity: WebEntity<EnvasesAndPalletCompliance> = {
  name: 'envasesAndPalletCompliance',
  endpoint: 'reportes/envasesAndPalletCompliance',
  referenceColumn: 'zoneName',
  tableColumns: []
};

export default EnvasesAndPalletComplianceEntity;
