import React, {
  FunctionComponent,
  ReactNode,
  useState,
  CSSProperties,
  useEffect,
  useCallback,
  SetStateAction,
  Dispatch,
  useMemo
} from 'react';
import { connect } from 'react-redux';
import { OCA, Documento, SimpleObject, TipoCobro, stateRules, EstadoOCA, Cobro } from '../types';
import { getUncatchEndpointData } from '../utils/request';
import { addObjectToStorage, removeFromStorage } from '../utils/storage';
import { Fields, WebEntityName } from '../entities/types';
import { popAlert, PopAlertType } from './PopAlert';
import {
  refreshConteoCriticidadLog,
  refreshReporteInconsistenciaFechaLog,
  cleanSelectedEntities,
  agregarOca,
  dateFilterEntity,
  objetarOcaPorOrigen,
  objetarOcaPorEett,
  decisionEnDiscusionOCA,
  crearCobro,
  contraResponderOcaPorDestino,
  reabrirOca,
  agregarIdCobroCcu,
  agregarCartaCobranza
} from '../actions/middleActions';
import GenericIcons from './generics/GenericIcons';
import { Alert, Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { WebEntity } from '../new-table/entities/types';
import * as newE from '../new-table/entities';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DateView } from '@mui/x-date-pickers';
import { toast } from 'react-toastify';
import moment from 'moment';
import ModalComponent from '../new-table/components/ModalComponent';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd';
import { message, Upload, Button as AntdButton } from 'antd';
import InputField from './InputField';
import { dateFormatFn } from '../utils/utils';
import { hasTimePassed } from '../new-table/utils';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RestoreIcon from '@mui/icons-material/Restore';
import Swal from 'sweetalert2';

const { Dragger } = Upload;

type ButtonParams = {
  action: (params?: any, webEntity?: any) => Promise<any>;
  selected: any[];
};

type Props = {
  action: (params?: any) => Promise<any>;
  onClick: (buttonParams: ButtonParams) => Promise<void>;
  children: ReactNode;
  selected?: any[];
  style?: CSSProperties;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  disabled?: boolean;
};

const SecondaryButton: FunctionComponent<Props> = ({
  action,
  onClick,
  selected = [],
  children,
  style,
  variant,
  color,
  disabled
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnClick = async () => {
    setLoading(true);
    await onClick({ action, selected });
    setLoading(false);
  };

  return (
    <Button
      onClick={handleOnClick}
      disabled={loading || disabled}
      style={style}
      variant={variant}
      color={color}>
      {children}
    </Button>
  );
};

const connectedSecondaryButton = (action: any) => connect(null, { action })(SecondaryButton);

/* const genericOnClick = async (buttonParams: ButtonParams) => {
 *   const { action, selected } = buttonParams;
 *   await action(selected.map((s: any) => s._id));
 * }; */

/* export const exampleButton = ({
 *   selected,
 *   refreshStatus
 * }: {
 *   selected: TypeOfSelected[];
 *   refreshStatus: TypeOfStatus;
 * }) => {
 *   const ConnectedBtn = connectedSecondaryButton(actionToPerform(refreshStatus));
 *
 *   return (
 *     <ConnectedBtn
 *       onClick={genericOnClick}
 *       selected={selected}
 *       btnProps={{ basic: true, color: 'orange', inverted: true }}>
 *       <Icon name='undo' />
 *       Deshacer
 *     </ConnectedBtn>
 *   );
 * }; */

export const RefreshConteoCriticidadLogButton = () => {
  const ConnectedBtn = connectedSecondaryButton(refreshConteoCriticidadLog);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action();
  };

  return (
    <ConnectedBtn onClick={onClick} variant='contained'>
      <RefreshIcon />
      Refrescar
    </ConnectedBtn>
  );
};

export const RefreshReporteInconsistenciaFechaLogButton = () => {
  const ConnectedBtn = connectedSecondaryButton(refreshReporteInconsistenciaFechaLog);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action();
  };

  return (
    <ConnectedBtn onClick={onClick} variant='contained'>
      <RefreshIcon />
      Refrescar
    </ConnectedBtn>
  );
};

export const DuplicateDocMultiForm = ({
  selected,
  endpoint,
  endpointNameMultiInput,
  fields,
  entityName
}: {
  selected: Array<Documento>;
  endpoint: string;
  endpointNameMultiInput: string;
  fields: Fields<any>[];
  entityName: WebEntityName;
}) => {
  const documentoId = selected.map((item) => item._id);
  const ConnectedBtn = connectedSecondaryButton(cleanSelectedEntities);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    if (documentoId.length > 1) {
      popAlert({
        type: PopAlertType.ERROR,
        title: `Accion no permitida`,
        details: `No se permite duplicar mas de un conteo`
      });
      return;
    }

    const documentData = await getUncatchEndpointData({
      endpoint: `${endpointNameMultiInput}/getDocumentById`,
      query: {
        documentoId: documentoId
      }
    });

    const conteoData = await getUncatchEndpointData({
      endpoint: `${endpoint}/getDataByDocumentId`,
      query: {
        documentoId: documentoId
      }
    });

    const centro = await getUncatchEndpointData({
      endpoint: `centroInventario/nombreCentro`,
      query: {
        zonaDeTransporte: conteoData[0].bodega
      }
    });

    documentData.zonaDeTransporte = centro.nombreCentro;

    removeFromStorage(endpointNameMultiInput);
    const multiInputFields = [];
    const state: Record<string, string> = {};
    const headerFields = fields.filter((f: any) => !f.multiInput?.multiField);
    const multiFields = fields.filter((f: any) => f.multiInput?.multiField);

    headerFields
      .filter((field) => field.multiInput?.isDuplicable)
      .map((field) => {
        state[field.selector] = documentData[field.selector];
      });

    for (let i = 0; i < conteoData.length; i++) {
      multiInputFields.push(multiFields);

      multiFields
        .filter((field) => field.multiInput?.isDuplicable)
        .map((field) => {
          state[`${field.selector}_${i}`] = conteoData[i][field.selector];
        });
    }
    await addObjectToStorage(endpointNameMultiInput, {
      multiInputFields: multiInputFields,
      state: state
    });

    await action(entityName);

    const btn = document.getElementById(endpointNameMultiInput) as HTMLButtonElement;
    if (btn) {
      btn.click();
    }
  };

  return (
    <ConnectedBtn onClick={onClick} variant='contained'>
      <GenericIcons icon='duplicateWhite' />
      Duplicar
    </ConnectedBtn>
  );
};

const transformFilesToString = async (
  files: File[] | null
): Promise<{ name: string; type: string; content: string }[]> => {
  if (!files) return [];

  const filePromises = Array.from(files).map((file) => {
    return new Promise<{ name: string; type: string; content: string }>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve({
          name: file.name,
          type: file.type,
          content: reader.result as string
        });
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  });

  return await Promise.all(filePromises);
};

export const AgregarCobro: FunctionComponent<{
  tipoCobro: TipoCobro;
  data: {
    cdOrigen: string;
    cdDestino: string;
    eett: string;
    oca: string;
    patente: string;
    dateOca: moment.Moment | null;
    detalle: string;
    causal: string;
    files: File[] | null;
    skuData: Array<{
      sku: string;
      cantidad: string;
    }>;
  };
  disabled: boolean;
  toggleModal: () => void;
  setLoadingOutward: Dispatch<SetStateAction<boolean>>;
  webEntity: WebEntity<OCA>;
}> = ({ tipoCobro, data, disabled, toggleModal, setLoadingOutward, webEntity }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [faltaInfo, setFaltaInfo] = useState<boolean>(false);

  useEffect(() => setLoadingOutward(loading), [setLoadingOutward, loading]);

  const validateData = useCallback(() => {
    if (
      !data.cdOrigen ||
      !data.cdDestino ||
      !data.eett ||
      !data.oca ||
      !data.patente ||
      data.patente.length !== 6 ||
      !data.dateOca ||
      !data.detalle ||
      (tipoCobro === TipoCobro.merma && !data.causal) ||
      !data.files ||
      data.files.length === 0 ||
      !data.skuData ||
      data.skuData.length === 0
    ) {
      return true;
    }

    const isSkuDataValid = data.skuData.every(
      (skuItem) => skuItem.sku && Number(skuItem.cantidad) > 0
    );

    if (!isSkuDataValid) {
      return true;
    }

    return false;
  }, [data]);

  useEffect(() => setFaltaInfo(validateData()), [validateData]);

  const ConnectedBtn = connectedSecondaryButton(agregarOca);

  const onClick = async (buttonParams: ButtonParams) => {
    if (!tipoCobro) {
      alert('No se recibio el tipo de cobro. Contacte al administrador del sistema');
      setLoading(false);
      return;
    }
    toast.promise(
      async () => {
        setLoading(true);
        await Swal.fire({
          title: 'Confirmar acción',
          text: '¿Estás seguro de que todos los datos ingresados están correctos?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Sí, finalizar',
          cancelButtonText: 'No, revisar',
          reverseButtons: true,
          confirmButtonColor: '#1b5e20',
          cancelButtonColor: '#ff0031'
        })
          .then(async (result) => {
            if (result.isConfirmed) {
              const { action } = buttonParams;
              const processedFiles = await transformFilesToString(data.files);
              const res = await action(
                {
                  ...data,
                  files: processedFiles,
                  dateOca: data.dateOca?.format('YYYY-MM-DD'),
                  tipoCobro
                },
                webEntity
              );

              if (res) {
                toggleModal();
                return;
              }
              throw Error();
            }
            Swal.fire({
              title: 'Puedes continuar editando',
              text: 'Por favor revisa los datos antes de finalizar',
              icon: 'info',
              confirmButtonText: 'Entendido',
              confirmButtonColor: '#1b5e20'
            });
            throw Error();
          })
          .finally(() => {
            setLoading(false);
          });
      },
      {
        pending: 'Cargando...',
        success: `${
          tipoCobro === TipoCobro.merma ? 'La' : 'El'
        } ${tipoCobro} se agregó correctamente.`,
        error: 'No se ha completado la carga. Intenta nuevamente'
      }
    );
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <ConnectedBtn onClick={onClick} variant='contained' disabled={loading || faltaInfo || disabled}>
      Finalizar
    </ConnectedBtn>
  );
};

export const DateFilter: FunctionComponent<{
  webEntity: WebEntity<any>;
  viewsDate: DateView[];
  additionalParams?: SimpleObject;
}> = ({ webEntity, viewsDate, additionalParams }) => {
  const ConnectedBtn = connectedSecondaryButton(dateFilterEntity);
  const [isFiltered, setIsFiltered] = useState<SimpleObject | null>(null);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    const date = selectedDate ? selectedDate.format('YYYY-MM-DD') : null;

    const dateFormattedMsj = selectedDate
      ? viewsDate.includes('month')
        ? selectedDate.format('MM-YYYY')
        : selectedDate.format('YYYY')
      : '';

    toast.promise(
      async () => {
        setLoading(true);
        const res = await action({ date, additionalParams }, webEntity);
        setLoading(false);
        setIsFiltered(res);
        return res;
      },
      {
        pending: 'Cargando...',
        success: `Filtrado! ${dateFormattedMsj ? `Fecha: ${dateFormattedMsj}` : ''}`,
        error: 'Ocurrió un error al realizar el filtro'
      }
    );
  };

  const onClickRemoveFilters = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    toast.promise(
      async () => {
        const currentDate = moment(new Date());
        setLoading(true);
        const res = await action(
          { date: currentDate.format('YYYY-MM-DD'), additionalParams },
          webEntity
        );
        setLoading(false);
        setIsFiltered(null);
        setSelectedDate(null);
        return res;
      },
      {
        pending: 'Cargando...',
        success: `Se quitó el filtro!`,
        error: 'Ocurrió un error al quitar el filtro'
      }
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
      {isFiltered && (
        <ConnectedBtn
          onClick={onClickRemoveFilters}
          disabled={!isFiltered || loading}
          color='inherit'
          style={{ padding: '12px' }}>
          <FilterAltOffIcon color='action' fontSize='large' />
        </ConnectedBtn>
      )}

      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
        <DatePicker
          views={viewsDate}
          openTo='year'
          value={selectedDate}
          onChange={(date) => setSelectedDate(date)}
        />
      </LocalizationProvider>

      <ConnectedBtn
        onClick={onClick}
        disabled={!selectedDate || loading}
        color='inherit'
        style={{ padding: '12px' }}>
        <SearchIcon fontSize='large' />
      </ConnectedBtn>
    </div>
  );
};

export const ObjetarOcaPorOrigen: FunctionComponent<{ rowData: OCA; tipoCobro: TipoCobro }> = ({
  rowData,
  tipoCobro
}) => {
  const ConnectedBtn = connectedSecondaryButton(objetarOcaPorOrigen);
  const [loading, setLoading] = useState<boolean>(false);
  const [comentario, setComentario] = useState<string>('');
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [timeLeft, setTimeLeft] = useState<boolean>(
    hasTimePassed(
      new Date(dateFormatFn(rowData.fechaOcaIngresada, 'YYYY-MM-DD HH:mm:ss', true)),
      rowData.ocaReabierta && rowData.estadoOcaActual === rowData.estadoOcaAnterior
        ? stateRules[EstadoOCA.ingresada].reopenedDays
        : stateRules[EstadoOCA.ingresada].normalDays
    ).hasPassed
  );
  const [open, setOpen] = useState<boolean>(false);
  const handleToggleModal = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(
        hasTimePassed(
          new Date(dateFormatFn(rowData.fechaOcaIngresada, 'YYYY-MM-DD HH:mm:ss', true)),
          rowData.ocaReabierta && rowData.estadoOcaActual === rowData.estadoOcaAnterior
            ? stateRules[EstadoOCA.ingresada].reopenedDays
            : stateRules[EstadoOCA.ingresada].normalDays
        ).hasPassed
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, [rowData.fechaOcaIngresada]);

  const entity =
    tipoCobro === TipoCobro.faltante ? newE.OcaFaltanteIngresada : newE.OcaMermaIngresada;

  const onClickNoObjetar = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: '¿Seguro de que deseas NO objetar?',
      text: 'La OCA se cerrará',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;
        toast.promise(
          async () => {
            setLoading(true);
            const res = await action({ ocaId: rowData._id, objetar: false, tipoCobro }, entity);
            setLoading(false);
            if (res) {
              setFiles([]);
              setComentario('');
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  const onClickObjetar = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: '¿Confirmas la objeción?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;

        const processedFiles = await transformFilesToString(
          files.map((file) => file.originFileObj as File).filter(Boolean)
        );

        toast.promise(
          async () => {
            setLoading(true);
            const res = await action(
              { ocaId: rowData._id, objetar: true, tipoCobro, comentario, files: processedFiles },
              entity
            );
            setLoading(false);
            if (res) {
              setFiles([]);
              setComentario('');
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  const allowedFileTypes = ['pdf', 'jpg', 'jpeg', 'png'];
  const MAX_FILES = 2;

  const beforeUpload = (file: File) => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    const isAllowed = allowedFileTypes.includes(fileType || '');

    if (!isAllowed) {
      message.error(`El archivo ${file.name} no es un formato permitido.`);
      return Upload.LIST_IGNORE;
    }

    if (fileType === 'pdf' && file.size > 2 * 1024 * 1024) {
      message.error(`El archivo ${file.name} excede los 2 MB permitidos para PDFs.`);
      return Upload.LIST_IGNORE;
    }

    if (['jpg', 'jpeg', 'png'].includes(fileType || '') && file.size > 2 * 1024 * 1024) {
      message.warning(
        `La imagen ${file.name} excede el tamaño máximo de 2 MB, se cargará pero en menor calidad.`
      );
    }

    return false;
  };

  const handleChange = ({ fileList }: { fileList: UploadFile[] }) => {
    const newFiles = fileList.slice(0, MAX_FILES);
    setFiles(newFiles);
  };

  return timeLeft ? (
    <Alert severity='error'>Se acabó el tiempo</Alert>
  ) : (
    <div style={{ display: 'flex', gap: '5px' }}>
      <ModalComponent
        modalTitle='Objeción'
        disabledModalBtn={loading}
        toggleModal={handleToggleModal}
        isModalOpen={open}
        body={
          <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px' }}>
            <InputField
              label='Comentario'
              value={comentario}
              type='text'
              handleInputChange={(e) => {
                setComentario(e.target.value);
              }}
              maxLength={300}
              disabled={loading}
              fullWidth
              required
            />
            <Dragger
              beforeUpload={beforeUpload}
              onChange={handleChange}
              multiple
              maxCount={MAX_FILES}
              showUploadList
              fileList={files}>
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
              <p className='ant-upload-text'>
                Selecciona o arrastra el archivo a esta zona para cargarlo
              </p>
              <p className='ant-upload-hint'>
                <strong>Máximo de archivos permitidos:</strong> {MAX_FILES}
              </p>
              <p className='ant-upload-hint'>Formatos permitidos: PDF, JPG, JPEG, PNG</p>
            </Dragger>
          </div>
        }
        actionBtn={
          <ConnectedBtn
            onClick={onClickObjetar}
            disabled={loading || !comentario || files.length === 0}
            color='success'
            variant='contained'
            style={{ gap: '2px' }}>
            Objetar
          </ConnectedBtn>
        }
        btnLabel='Si'
        btnVariant='contained'
        btnColor='info'
        customStyles={{
          modalContainer: {
            height: 'auto'
          }
        }}
      />

      <ConnectedBtn
        onClick={onClickNoObjetar}
        disabled={loading}
        color='error'
        variant='contained'
        style={{ gap: '2px' }}>
        No
      </ConnectedBtn>
    </div>
  );
};

export const ObjetarOcaPorEett: FunctionComponent<{ rowData: OCA; tipoCobro: TipoCobro }> = ({
  rowData,
  tipoCobro
}) => {
  const ConnectedBtn = connectedSecondaryButton(objetarOcaPorEett);
  const [loading, setLoading] = useState<boolean>(false);
  const [comentario, setComentario] = useState<string>('');
  const [file, setFile] = useState<UploadFile | null>(null);
  const [timeLeft, setTimeLeft] = useState<boolean>(
    hasTimePassed(
      new Date(dateFormatFn(rowData.fechaOcaObjetadaOrigen, 'YYYY-MM-DD HH:mm:ss', true)),
      rowData.ocaReabierta && rowData.estadoOcaActual === rowData.estadoOcaAnterior
        ? stateRules[EstadoOCA.objetadaOrigen].reopenedDays
        : stateRules[EstadoOCA.objetadaOrigen].normalDays
    ).hasPassed
  );
  const [open, setOpen] = useState<boolean>(false);
  const handleToggleModal = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(
        hasTimePassed(
          new Date(dateFormatFn(rowData.fechaOcaObjetadaOrigen, 'YYYY-MM-DD HH:mm:ss', true)),
          rowData.ocaReabierta && rowData.estadoOcaActual === rowData.estadoOcaAnterior
            ? stateRules[EstadoOCA.objetadaOrigen].reopenedDays
            : stateRules[EstadoOCA.objetadaOrigen].normalDays
        ).hasPassed
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, [rowData.fechaOcaObjetadaOrigen]);

  const entity =
    tipoCobro === TipoCobro.faltante ? newE.OcaFaltanteObjetadaOrigen : newE.OcaMermaObjetadaOrigen;

  const onClickNo = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: '¿Seguro de que deseas NO objetar?',
      text: 'La OCA pasará a ser Cobrable',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;
        toast.promise(
          async () => {
            setLoading(true);
            const res = await action({ ocaId: rowData._id, objetar: false, tipoCobro }, entity);
            setLoading(false);
            if (res) {
              setComentario('');
              setFile(null);
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  const onClickSi = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: '¿Confirmas la objeción?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;

        const processedFiles = await transformFilesToString(
          file ? [file?.originFileObj as File] : null
        );

        toast.promise(
          async () => {
            setLoading(true);
            const res = await action(
              {
                ocaId: rowData._id,
                objetar: true,
                tipoCobro,
                comentario,
                file: processedFiles
              },
              entity
            );
            setLoading(false);
            if (res) {
              setComentario('');
              setFile(null);
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  const allowedFileTypes = ['pdf', 'jpg', 'jpeg', 'png'];
  const MAX_FILES = 1;

  const beforeUpload = (file: File) => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    const isAllowed = allowedFileTypes.includes(fileType || '');

    if (!isAllowed) {
      message.error(`El archivo ${file.name} no es un formato permitido.`);
      return Upload.LIST_IGNORE;
    }

    if (fileType === 'pdf' && file.size > 2 * 1024 * 1024) {
      message.error(`El archivo ${file.name} excede los 2 MB permitidos para PDFs.`);
      return Upload.LIST_IGNORE;
    }

    if (['jpg', 'jpeg', 'png'].includes(fileType || '') && file.size > 2 * 1024 * 1024) {
      message.warning(
        `La imagen ${file.name} excede el tamaño máximo de 2 MB, se cargará pero en menor calidad.`
      );
    }

    return false;
  };

  const handleChange = ({ fileList }: { fileList: UploadFile[] }) => {
    const newFile = fileList.slice(0, MAX_FILES);
    setFile(newFile[0]);
  };

  return timeLeft ? (
    <Alert severity='error'>Se acabó el tiempo</Alert>
  ) : (
    <div style={{ display: 'flex', gap: '5px' }}>
      <ModalComponent
        modalTitle='Contra respuesta'
        disabledModalBtn={loading}
        toggleModal={handleToggleModal}
        isModalOpen={open}
        body={
          <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px' }}>
            <InputField
              label='Comentario'
              value={comentario}
              type='text'
              handleInputChange={(e) => {
                setComentario(e.target.value);
              }}
              maxLength={300}
              disabled={loading}
              fullWidth
              required
            />
            <div>
              <Upload
                beforeUpload={beforeUpload}
                onChange={handleChange}
                multiple={false}
                maxCount={MAX_FILES}
                showUploadList
                fileList={file ? [file] : []}>
                <AntdButton icon={<UploadOutlined />}>Seleccionar archivo</AntdButton>
              </Upload>
              <p style={{ marginTop: 10 }}>
                <strong>Máximo de archivos permitidos:</strong> {MAX_FILES}
              </p>
              <p>Solo se permite subir 1 archivo de tipo PDF (máx. 2 MB), JPG, JPEG o PNG</p>
            </div>
          </div>
        }
        actionBtn={
          <ConnectedBtn
            onClick={onClickSi}
            disabled={loading || !comentario || !file}
            color='success'
            variant='contained'
            style={{ gap: '2px' }}>
            Objetar
          </ConnectedBtn>
        }
        btnLabel='Si'
        btnVariant='contained'
        btnColor='info'
        customStyles={{
          modalContainer: {
            height: 'auto'
          }
        }}
      />

      <ConnectedBtn
        onClick={onClickNo}
        disabled={loading}
        color='error'
        variant='contained'
        style={{ gap: '2px' }}>
        No
      </ConnectedBtn>
    </div>
  );
};

export const ContraResponderOcaPorDestino: FunctionComponent<{
  rowData: OCA;
  tipoCobro: TipoCobro;
}> = ({ rowData, tipoCobro }) => {
  const ConnectedBtn = connectedSecondaryButton(contraResponderOcaPorDestino);
  const [loading, setLoading] = useState<boolean>(false);
  const [comentario, setComentario] = useState<string>('');
  const [timeLeft, setTimeLeft] = useState<boolean>(
    hasTimePassed(
      new Date(dateFormatFn(rowData.fechaOcaObjetadaTransportista, 'YYYY-MM-DD HH:mm:ss', true)),
      rowData.ocaReabierta && rowData.estadoOcaActual === rowData.estadoOcaAnterior
        ? stateRules[EstadoOCA.objetadaTransportista].reopenedDays
        : stateRules[EstadoOCA.objetadaTransportista].normalDays
    ).hasPassed
  );
  const [open, setOpen] = useState<boolean>(false);
  const handleToggleModal = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(
        hasTimePassed(
          new Date(
            dateFormatFn(rowData.fechaOcaObjetadaTransportista, 'YYYY-MM-DD HH:mm:ss', true)
          ),
          rowData.ocaReabierta && rowData.estadoOcaActual === rowData.estadoOcaAnterior
            ? stateRules[EstadoOCA.objetadaTransportista].reopenedDays
            : stateRules[EstadoOCA.objetadaTransportista].normalDays
        ).hasPassed
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, [rowData.fechaOcaObjetadaTransportista]);

  const entity =
    tipoCobro === TipoCobro.faltante
      ? newE.OcaFaltanteObjetadaTransportista
      : newE.OcaMermaObjetadaTransportista;

  const onClickNo = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: '¿Seguro de que deseas NO contra responder?',
      text: 'La OCA se cerrará',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;
        toast.promise(
          async () => {
            setLoading(true);
            const res = await action(
              { ocaId: rowData._id, contraRespuesta: false, tipoCobro },
              entity
            );
            setLoading(false);
            if (res) {
              setComentario('');
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  const onClickSi = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: '¿Confirmas la contra respuesta?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;

        toast.promise(
          async () => {
            setLoading(true);
            const res = await action(
              {
                ocaId: rowData._id,
                contraRespuesta: true,
                tipoCobro,
                comentario
              },
              entity
            );
            setLoading(false);
            if (res) {
              setComentario('');
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  return timeLeft ? (
    <Alert severity='error'>Se acabó el tiempo</Alert>
  ) : (
    <div style={{ display: 'flex', gap: '5px' }}>
      <ModalComponent
        modalTitle='Contra respuesta'
        disabledModalBtn={loading}
        toggleModal={handleToggleModal}
        isModalOpen={open}
        body={
          <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px' }}>
            <InputField
              label='Comentario'
              value={comentario}
              type='text'
              handleInputChange={(e) => {
                setComentario(e.target.value);
              }}
              maxLength={300}
              disabled={loading}
              fullWidth
              required
            />
          </div>
        }
        actionBtn={
          <ConnectedBtn
            onClick={onClickSi}
            disabled={loading || !comentario}
            color='success'
            variant='contained'
            style={{ gap: '2px' }}>
            Contra responder
          </ConnectedBtn>
        }
        btnLabel='Si'
        btnVariant='contained'
        btnColor='info'
        customStyles={{
          modalContainer: {
            height: 'auto'
          }
        }}
      />

      <ConnectedBtn
        onClick={onClickNo}
        disabled={loading}
        color='error'
        variant='contained'
        style={{ gap: '2px' }}>
        No
      </ConnectedBtn>
    </div>
  );
};

export const DecisionEnDiscusionOCA: FunctionComponent<{
  rowData: OCA;
  tipoCobro: TipoCobro;
}> = ({ rowData, tipoCobro }) => {
  const ConnectedBtn = connectedSecondaryButton(decisionEnDiscusionOCA);
  const [loading, setLoading] = useState<boolean>(false);
  const [comentario, setComentario] = useState<string>('');
  const [timeLeft, setTimeLeft] = useState<boolean>(
    hasTimePassed(
      new Date(dateFormatFn(rowData.fechaOcaEnDiscusion, 'YYYY-MM-DD HH:mm:ss', true)),
      rowData.ocaReabierta && rowData.estadoOcaActual === rowData.estadoOcaAnterior
        ? stateRules[EstadoOCA.enDiscusion].reopenedDays
        : stateRules[EstadoOCA.enDiscusion].normalDays
    ).hasPassed
  );
  const [open, setOpen] = useState<boolean>(false);
  const handleToggleModal = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(
        hasTimePassed(
          new Date(dateFormatFn(rowData.fechaOcaEnDiscusion, 'YYYY-MM-DD HH:mm:ss', true)),
          rowData.ocaReabierta && rowData.estadoOcaActual === rowData.estadoOcaAnterior
            ? stateRules[EstadoOCA.enDiscusion].reopenedDays
            : stateRules[EstadoOCA.enDiscusion].normalDays
        ).hasPassed
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, [rowData.fechaOcaEnDiscusion]);

  const entity =
    tipoCobro === TipoCobro.faltante ? newE.OcaFaltanteEnDiscusion : newE.OcaMermaEnDiscusion;

  const onClickCD = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: '¿Seguro de que deseas hacer responsable al CD?',
      text: 'Esto provocará que la OCA se de por CERRADA',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;
        toast.promise(
          async () => {
            setLoading(true);
            const res = await action(
              { ocaId: rowData._id, cobrable: false, tipoCobro, comentario },
              entity
            );
            setLoading(false);
            if (res) {
              setComentario('');
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  const onClickTransportista = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: '¿Seguro de que deseas hacer responsable al Transportista?',
      text: 'Esto provocará que la OCA quede disponible para ser COBRADA',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;

        toast.promise(
          async () => {
            setLoading(true);
            const res = await action(
              { ocaId: rowData._id, cobrable: true, tipoCobro, comentario },
              entity
            );
            setLoading(false);
            if (res) {
              setComentario('');
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  return timeLeft ? (
    <Alert severity='error'>Se acabó el tiempo</Alert>
  ) : (
    <ModalComponent
      modalTitle='¿Quien es responsable?'
      disabledModalBtn={loading}
      toggleModal={handleToggleModal}
      isModalOpen={open}
      body={
        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px' }}>
          <InputField
            label='Comentario'
            value={comentario}
            type='text'
            handleInputChange={(e) => {
              setComentario(e.target.value);
            }}
            maxLength={300}
            disabled={loading}
            fullWidth
            required
          />
        </div>
      }
      actionBtn={
        <div
          style={{
            display: 'flex',
            gap: '5px',
            width: '100%',
            justifyContent: 'flex-end'
          }}>
          <ConnectedBtn
            onClick={onClickCD}
            disabled={loading || !comentario}
            color='primary'
            variant='contained'
            style={{ gap: '5px' }}>
            <WarehouseIcon />
            CD Origen
          </ConnectedBtn>

          <ConnectedBtn
            onClick={onClickTransportista}
            disabled={loading || !comentario}
            color='primary'
            variant='contained'
            style={{ gap: '5px' }}>
            <LocalShippingIcon />
            EETT
          </ConnectedBtn>
        </div>
      }
      btnLabel='Decidir'
      btnVariant='contained'
      btnColor='info'
      customStyles={{
        modalContainer: {
          height: 'auto'
        },
        modalBtnContainer: {
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }
      }}
    />
  );
};

export const CrearCobro: FunctionComponent<{
  selected: Array<OCA>;
  tipoCobro: TipoCobro;
}> = ({ selected, tipoCobro }) => {
  const ConnectedBtn = connectedSecondaryButton(crearCobro);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [transportistasDiferentes, setTransportistasDiferentes] = useState<boolean>(false);
  const [numeroDocumentoMaterial, setNumeroDocumentoMaterial] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const handleToggleModal = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const selectedOcas = useMemo(
    () =>
      selected.reduce(
        (acc, obj) => {
          if (acc.every((a) => a._id !== obj._id)) {
            acc.push({
              _id: obj._id,
              oca: obj.oca,
              nombreTransportista: obj.nombreTransportista,
              fechaOcaCobrable: obj.fechaOcaCobrable,
              ocaReabierta: obj.ocaReabierta,
              estadoOcaActual: obj.estadoOcaActual,
              estadoOcaAnterior: obj.estadoOcaAnterior
            });
          }
          return acc;
        },
        [] as Array<{
          _id: string;
          oca: string;
          nombreTransportista: string;
          fechaOcaCobrable: string;
          ocaReabierta: boolean;
          estadoOcaActual: string;
          estadoOcaAnterior: string;
        }>
      ),
    [selected]
  );

  useEffect(() => {
    if (selectedOcas.length === 0) {
      setError(undefined);
      return;
    }
    const primerTransportista = selectedOcas[0]?.nombreTransportista;

    setTransportistasDiferentes(
      selectedOcas.some((obj) => obj.nombreTransportista !== primerTransportista)
    );
  }, [selectedOcas]);

  useEffect(() => {
    if (transportistasDiferentes) {
      setError('Los cobros deben ser para un mismo Transportista');
    } else {
      setError(undefined);
    }
  }, [transportistasDiferentes]);

  useEffect(() => {
    const validarDatos = () => {
      if (transportistasDiferentes) {
        setError('Los cobros deben ser para un mismo Transportista');
      } else {
        setError(undefined);
      }
    };

    const intervalId = setInterval(() => {
      validarDatos();
    }, 500);

    return () => clearInterval(intervalId);
  }, [selectedOcas, transportistasDiferentes]);

  const entity =
    tipoCobro === TipoCobro.faltante ? newE.OcaFaltanteCobrable : newE.OcaMermaCobrable;

  const onClick = async (buttonParams: ButtonParams) => {
    if (selectedOcas.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Debes seleccionar al menos una OCA'
      });
      return;
    }

    const { action } = buttonParams;
    toast.promise(
      async () => {
        setLoading(true);
        const res = await action(
          { ocas: selectedOcas, tipoCobro, numeroDocumentoMaterial },
          entity
        );
        setLoading(false);
        if (res) {
          setNumeroDocumentoMaterial('');
          handleToggleModal(false);
          return res;
        }
        throw Error;
      },
      {
        pending: 'Cargando...',
        success: 'Accion realizada',
        error: 'Ocurrió un error al realizar la acción'
      }
    );
  };

  return selectedOcas.length > 0 ? (
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <ModalComponent
        modalTitle='Generar Cobro'
        disabledModalBtn={loading || !!error}
        toggleModal={handleToggleModal}
        isModalOpen={open}
        body={
          <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px' }}>
            <h4>Crearas un cobro para las siguientes ocas</h4>
            <ul>
              {selectedOcas.map((s) => (
                <li>{s.oca}</li>
              ))}
            </ul>
            <InputField
              label='Numero de documento del material'
              value={numeroDocumentoMaterial}
              type='text'
              handleInputChange={(e) => {
                const sanitizedValue = (e.target.value || '').replace(/[^0-9]/g, '');
                setNumeroDocumentoMaterial(sanitizedValue);
              }}
              disabled={loading}
              fullWidth
              required
            />
          </div>
        }
        actionBtn={
          <div
            style={{
              display: 'flex',
              gap: '5px',
              width: '100%',
              justifyContent: 'flex-end'
            }}>
            <ConnectedBtn
              onClick={onClick}
              disabled={loading || !!error || !numeroDocumentoMaterial}
              color='success'
              variant='contained'
              style={{ gap: '5px' }}>
              Generar Cobro ({selectedOcas.length})
            </ConnectedBtn>
          </div>
        }
        btnLabel='Generar Cobro'
        btnVariant='contained'
        btnColor='success'
        btnIcon={<CurrencyExchangeIcon />}
        customStyles={{
          modalContainer: {
            height: 'auto'
          },
          modalBtnContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }
        }}
      />

      {error && (
        <Alert variant='filled' severity='error'>
          {error}
        </Alert>
      )}
    </div>
  ) : (
    <></>
  );
};

export const ReabrirOca: FunctionComponent<{
  rowData: OCA;
  tipoCobro: TipoCobro;
}> = ({ rowData, tipoCobro }) => {
  const ConnectedBtn = connectedSecondaryButton(reabrirOca);
  const [loading, setLoading] = useState<boolean>(false);

  const entity =
    tipoCobro === TipoCobro.faltante ? newE.OcaFaltanteExpirada : newE.OcaMermaExpirada;

  const onClick = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: '¿Seguro de que deseas reabrir la OCA?',
      text: `Esto provocará que la OCA vuelva al estado '${rowData.estadoOcaAnterior}'`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;
        toast.promise(
          async () => {
            setLoading(true);
            const res = await action(
              {
                ocaId: rowData._id,
                tipoCobro,
                estadoOcaActual: rowData.estadoOcaActual,
                estadoOcaAnterior: rowData.estadoOcaAnterior
              },
              entity
            );
            setLoading(false);
            if (res) {
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  return (
    <ConnectedBtn
      onClick={onClick}
      disabled={loading}
      color='primary'
      variant='contained'
      style={{ gap: '5px' }}>
      <RestoreIcon />
      Reabrir
    </ConnectedBtn>
  );
};

export const AgregarIdCobroCcu: FunctionComponent<{
  rowData: Cobro;
  tipoCobro: TipoCobro;
}> = ({ rowData, tipoCobro }) => {
  const ConnectedBtn = connectedSecondaryButton(agregarIdCobroCcu);
  const [loading, setLoading] = useState<boolean>(false);
  const [idCobroCcu, setIdCobroCcu] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const handleToggleModal = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const onClick = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: `¿Seguro de que deseas asignar el ID '${idCobroCcu}'?`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;
        toast.promise(
          async () => {
            setLoading(true);
            const res = await action({
              cobroId: rowData.cobroId,
              tipoCobro,
              idCobroCcu
            });
            setLoading(false);
            if (res) {
              setIdCobroCcu('');
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  return (
    <ModalComponent
      modalTitle='Agregar ID Cobro'
      disabledModalBtn={loading}
      toggleModal={handleToggleModal}
      isModalOpen={open}
      body={
        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px' }}>
          <InputField
            label='ID Cobro'
            value={idCobroCcu}
            type='text'
            handleInputChange={(e) => {
              setIdCobroCcu((e.target.value || '').replace(' ', ''));
            }}
            disabled={loading}
            fullWidth
            required
          />
        </div>
      }
      actionBtn={
        <div
          style={{
            display: 'flex',
            gap: '5px',
            width: '100%',
            justifyContent: 'flex-end'
          }}>
          <ConnectedBtn
            onClick={onClick}
            disabled={loading || !idCobroCcu}
            color='primary'
            variant='contained'
            style={{ gap: '5px' }}>
            Agregar
          </ConnectedBtn>
        </div>
      }
      btnLabel='Agregar Id Cobro'
      btnVariant='contained'
      btnColor='success'
      customStyles={{
        modalContainer: {
          height: 'auto'
        },
        modalBtnContainer: {
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }
      }}
    />
  );
};

export const AgregarCartaCobranza: FunctionComponent<{
  rowData: Cobro;
  tipoCobro: TipoCobro;
}> = ({ rowData, tipoCobro }) => {
  const ConnectedBtn = connectedSecondaryButton(agregarCartaCobranza);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<UploadFile | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const handleToggleModal = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const onClick = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: `¿Seguro de que deseas asignar la carta de cobranzas: ${file?.name}?`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;
        toast.promise(
          async () => {
            setLoading(true);

            const processedFiles = await transformFilesToString(
              file ? [file?.originFileObj as File] : null
            );

            const res = await action({
              cobroId: rowData.cobroId,
              tipoCobro,
              file: processedFiles
            });
            setLoading(false);
            if (res) {
              setFile(null);
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  const allowedFile = 'pdf';
  const MAX_FILES = 1;

  const beforeUpload = (file: File) => {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    const isPDF = fileType === allowedFile;

    if (!isPDF) {
      message.error(`Solo se permiten archivos PDF.`);
      return Upload.LIST_IGNORE;
    }

    if (file.size > 2 * 1024 * 1024) {
      message.error(`El archivo ${file.name} excede los 2 MB permitidos.`);
      return Upload.LIST_IGNORE;
    }

    return false;
  };

  const handleChange = ({ fileList }: { fileList: UploadFile[] }) => {
    const newFile = fileList.slice(0, MAX_FILES);
    setFile(newFile[0]);
  };

  return (
    <ModalComponent
      modalTitle='Agregar carta cobranza'
      disabledModalBtn={loading}
      toggleModal={handleToggleModal}
      isModalOpen={open}
      body={
        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px' }}>
          <Upload
            beforeUpload={beforeUpload}
            onChange={handleChange}
            multiple={false}
            maxCount={MAX_FILES}
            showUploadList
            fileList={file ? [file] : []}>
            <AntdButton icon={<UploadOutlined />}>Seleccionar archivo</AntdButton>
          </Upload>
          <p style={{ marginTop: 10 }}>
            <strong>Máximo de archivos permitidos:</strong> {MAX_FILES}
          </p>
          <p>Solo se permite subir 1 archivo PDF (máx. 2 MB)</p>
        </div>
      }
      actionBtn={
        <div
          style={{
            display: 'flex',
            gap: '5px',
            width: '100%',
            justifyContent: 'flex-end'
          }}>
          <ConnectedBtn
            onClick={onClick}
            disabled={loading || !file}
            color='primary'
            variant='contained'
            style={{ gap: '5px' }}>
            Agregar
          </ConnectedBtn>
        </div>
      }
      btnLabel='Agregar archivo'
      btnVariant='contained'
      btnColor='success'
      customStyles={{
        modalContainer: {
          height: 'auto'
        },
        modalBtnContainer: {
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }
      }}
    />
  );
};
