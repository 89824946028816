import React from 'react';
import { TableColumn as TableColumReactDataTable } from 'react-data-table-component';
import { ImageFormats, SimpleObject } from '../types';

export enum FieldTypes {
  Text = 'text',
  Email = 'email',
  Rut = 'rut',
  Password = 'password',
  Boolean = 'boolean',
  Date = 'date',
  DateTime = 'dateTime',
  Reference = 'reference',
  Photo = 'photo',
  MultiReference = 'multiReference'
}

export type UpdateDependencyFunc = (
  value: any, // The value in the formfield
  getField: (fieldName: string) => any, // function to get a field from the entity
  setLoadings: (fields: any[], status: boolean) => void, // set a loading of a field
  updateFetchedFields: (map: SimpleObject) => void, // function to update 'fetchedFields' of GenericForm
  updateFieldValue: (fieldSelector: string, value: any, isMultiForm?: boolean) => void, // function to update the value from the formfield
  getFieldValue: (fieldSelector: string) => any, // function to get the value from the specified field
  getRow: () => any // function to get the row of an entity field
) => Promise<void>;

export type WebEntityName =
  | 'usuario'
  | 'tipoUsuario'
  | 'estadoDocumento'
  | 'sku'
  | 'skuFactor'
  | 'perfilEnvejecimiento'
  | 'diasVenta'
  | 'centro'
  | 'centroInventario'
  | 'zonasOperador'
  | 'activo'
  | 'tipoActivo'
  | 'nomenclatura'
  | 'relacionEnvaseCasillero'
  | 'comentario'
  | 'materialNomenclatura'
  | 'reporteZonaCategoriaSKU'
  | 'reporteVencimiento'
  | 'reporteInventarioCriticidad'
  | 'documentoCriticidad'
  | 'documentoEnvases'
  | 'documentoPallet'
  | 'inventarioCriticidad'
  | 'inventarioEnvases'
  | 'inventarioPallet'
  | 'conteoCriticidadLog'
  | 'inconsistenciaFechaLog'
  | 'usuarioTransportista'
  | 'transportista'
  | 'tipoCausal'
  | 'ocaMermaIngresada'
  | 'ocaFaltanteIngresada'
  | 'ocaMermaObjetadaOrigen'
  | 'ocaFaltanteObjetadaOrigen'
  | 'ocaMermaObjetadaTransportista'
  | 'ocaFaltanteObjetadaTransportista'
  | 'ocaMermaCobrable'
  | 'ocaFaltanteCobrable'
  | 'ocaMermaEnDiscusion'
  | 'ocaFaltanteEnDiscusion'
  | 'cobro'
  | 'ocaMermaCerrada'
  | 'ocaFaltanteCerrada'
  | 'ocaMermaExpirada'
  | 'ocaFaltanteExpirada'
  | 'cobroDashboard'
  | 'itemSociedad'
  | 'item'
  | 'sociedad'
  | 'envasesAndPalletCompliance';

type ConditionalCellStyle = {
  when: (row: any) => boolean;
  style: {
    [key: string]: string | number;
  };
};

export type ColumnType =
  | 'text'
  | 'boolean'
  | 'numeric'
  | 'date'
  | 'dateTime'
  | 'dateUTC'
  | 'dateTimeUTC'
  | 'percentage';

export type TableColumn<T> = TableColumReactDataTable<T> & {
  id: keyof T;
  columnType: ColumnType;
  omitExport?: boolean;
  valueToExport?: (row: T) => string;
  wrap?: boolean;
  omit?: boolean;
  center?: boolean;
  sortable?: boolean;
  format?: (entry: T) => string;
  conditionalCellStyles?: ConditionalCellStyle[];
  cell?: (
    row: any, // TODO: Fix fix the type to receive T instead of any
    rowIndex: number,
    column: TableColumReactDataTable<any>, // TODO: Fix fix the type to receive T instead of any
    id: string | number
  ) => React.ReactNode;
  allowOverflow?: boolean;
  button?: boolean;
  grow?: number;
  width?: string;
};

export type Fields<T> = {
  name: string;
  selector: string;
  type: FieldTypes;
  formatStr?: (value: string) => string;
  required?: boolean;
  multiInput?: {
    multiField: boolean;
    isDuplicable: boolean;
    setDefaultValueOnNewRow?: {
      // True, copy the value of this field as default on every new row you add
      previous: boolean; // True, copy previus field. False, copy first field
    };
  };
  disabled?: boolean;
  default?: boolean;
  onlyAdmin?: boolean;
  hidden?: boolean;
  defaultValue?: string | Date;
  updateDependency?: UpdateDependencyFunc;
  timerUpdateDependency?: boolean;
  timezone?: string;
  groupNumber?: number;
  columnClassName?: string;
  reference?: {
    name: string;
    endpoint?: string;
    endpointQuery?: (rowEntity: T) => SimpleObject; // NOTE: returns the object with the data that you want to send to the endpoint
    select: string;
    show: string;
    filter?: SimpleObject;
    data?: SimpleObject[];
  };
  constraints?: {
    min: number;
    max: number;
    format: ImageFormats;
  };
};

export type FilterFields<T> = Fields<T>;
type EditableFields<T> = Fields<T>;

export type WebEntity<T> = {
  name: WebEntityName;
  endpoint: string;
  referenceColumn: string;
  tableColumns: TableColumn<T>[];
  fields?: Fields<T>[];
  filterFields?: FilterFields<T>[];
  editableFields?: EditableFields<T>[];
};

export type WebEntityKeys<T> = keyof WebEntity<T>;
