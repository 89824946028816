import { Button, Chip, Tooltip } from '@mui/material';
import React, { CSSProperties, useState } from 'react';
import { FunctionComponent } from 'react';
import { spanishMonthNames } from '../utils/utils';
import { getUncatchEndpointData } from '../utils/request';
import { requestErrorPopAlert } from '../components/PopAlert';
import CircularProgress from '@mui/material/CircularProgress';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { EstadoOCA } from '../types';

export const hasTimePassed = (
  startDate: Date,
  daysRemaining: number
): { hasPassed: boolean; remainingTime: number } => {
  const currentDate = new Date();
  const targetDate = new Date(startDate);
  targetDate.setDate(targetDate.getDate() + daysRemaining);
  const remainingTime = targetDate.getTime() - currentDate.getTime();
  return {
    hasPassed: currentDate >= targetDate,
    remainingTime: remainingTime > 0 ? remainingTime : 0
  };
};

export const DownloadFile: FunctionComponent<{
  fileName: string;
  fileType: string;
  buttonName: string;
}> = ({ fileName, fileType, buttonName }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleDownload = async () => {
    try {
      setLoading(true);
      const res = await getUncatchEndpointData({
        endpoint: 'cobros/getFile',
        query: { fileName }
      });

      const byteCharacters = atob(res);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: fileType });

      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      requestErrorPopAlert(error);
    }
  };

  return loading ? (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
      }}>
      <CircularProgress />
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
      }}>
      <Button variant='contained' onClick={handleDownload} disabled={loading}>
        <FileDownloadIcon />
        {buttonName}
      </Button>
    </div>
  );
};

export const CustomTabLabel: FunctionComponent<{
  label: string;
  quantity: number;
  tooltip?: boolean;
}> = ({ label, quantity, tooltip }) => {
  const currentMonth = spanishMonthNames[new Date().getMonth()];
  const tooltipTitle =
    quantity > 0 ? `${quantity} registro${quantity > 1 ? 's' : ''} en ${currentMonth}` : '';

  return tooltip ? (
    <Tooltip title={tooltipTitle}>
      <div style={Styles.CustomTabLabelContainer}>
        {quantity > 0 && <span style={Styles.CustomTabLabelQuantity}>{quantity}</span>}
        <h3 style={Styles.CustomTabLabel}>{label}</h3>
      </div>
    </Tooltip>
  ) : (
    <div style={Styles.CustomTabLabelContainer}>
      {quantity > 0 && <span style={Styles.CustomTabLabelQuantity}>{quantity}</span>}
      <h3 style={Styles.CustomTabLabel}>{label}</h3>
    </div>
  );
};

const Styles: { [key: string]: CSSProperties } = {
  CustomTabLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    fontFamily: 'Roboto',
    justifyContent: 'center',
    alignItems: 'center'
  },
  CustomTabLabel: {
    fontSize: '10px',
    margin: 0
  },
  CustomTabLabelQuantity: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#64a70b',
    width: '25px',
    height: '25px',
    fontSize: '10px',
    borderRadius: '5px',
    color: '#fff',
    margin: 0
  }
};

export const estadoColors: Record<EstadoOCA, string> = {
  [EstadoOCA.ingresada]: '#3498db',
  [EstadoOCA.objetadaOrigen]: '#e67e22',
  [EstadoOCA.objetadaTransportista]: '#f97457',
  [EstadoOCA.enDiscusion]: '#e74c3c',
  [EstadoOCA.cobrable]: '#f1c40f',
  [EstadoOCA.cobrada]: '#2ecc71',
  [EstadoOCA.cerrada]: '#34495e',
  [EstadoOCA.expirada]: '#95a5a6'
};

export const CustomChip: React.FC<{ estadoOca: EstadoOCA }> = ({ estadoOca }) => {
  const color = estadoColors[estadoOca];

  return (
    estadoOca && (
      <Chip
        label={estadoOca}
        sx={{
          backgroundColor: color,
          color: '#ffffff'
        }}
      />
    )
  );
};
