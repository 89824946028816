import { Centro } from '../types';
import { booleanFormatFn } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';

const CentroEntity: WebEntity<Centro> = {
  name: 'centro',
  endpoint: 'centroInventario/centros',
  referenceColumn: '_id',
  tableColumns: [
    {
      name: 'ID',
      id: '_id',
      selector: (row) => row._id,
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'Centro',
      id: 'nombreCentro',
      selector: (row) => row.nombreCentro,
      columnType: 'text'
    },
    {
      name: 'Carga Pallet',
      id: 'cargaPallet',
      selector: (row) => row.cargaPallet,
      format: ({ cargaPallet }) => booleanFormatFn(cargaPallet),
      columnType: 'boolean'
    },
    {
      name: 'Carga CO2',
      id: 'cargaCo2',
      selector: (row) => row.cargaCo2,
      format: ({ cargaCo2 }) => booleanFormatFn(cargaCo2),
      columnType: 'boolean'
    },
    {
      name: 'Carga Envase',
      id: 'cargaEnvase',
      selector: (row) => row.cargaEnvase,
      format: ({ cargaEnvase }) => booleanFormatFn(cargaEnvase),
      columnType: 'boolean'
    }
  ],
  editableFields: [
    {
      name: 'Centro',
      selector: 'nombreCentro',
      type: FieldTypes.Text,
      required: true,
      disabled: true
    },
    {
      name: 'Carga Pallet',
      selector: 'cargaPallet',
      type: FieldTypes.Boolean,
      required: true
    },
    {
      name: 'Carga CO2',
      selector: 'cargaCo2',
      type: FieldTypes.Boolean,
      required: true
    },
    {
      name: 'Carga Envase',
      selector: 'cargaEnvase',
      type: FieldTypes.Boolean,
      required: true
    }
  ]
};

export default CentroEntity;
