import { WebEntity } from './types';
import { OCA, EstadoOCA } from '../../types';
import { WebEntityName } from '../../entities/types';
import OcaEntity, { AdditionalColumns } from './OcaEntity';
import UsuarioTransportistaEntity from './UsuarioTransportistaEntity';
import UsuarioEntity from './UsuarioEntity';
import TransportistasEntity from './TransportistasEntity';
import TipoCausalEntity from './TipoCausalEntity';
import CobroEntity from './CobroEntity';
import DashboardEntity from './DashboardEntity';
import EnvasesAndPalletComplianceEntity from './EnvasesAndPalletComplianceEntity';

const OcaMermaIngresada: WebEntity<OCA> = {
  ...OcaEntity,
  name: 'ocaMermaIngresada' as WebEntityName,
  tableColumns: [...OcaEntity.tableColumns, ...AdditionalColumns(EstadoOCA.ingresada)]
};

const OcaFaltanteIngresada: WebEntity<OCA> = {
  ...OcaEntity,
  name: 'ocaFaltanteIngresada' as WebEntityName,
  tableColumns: [...OcaEntity.tableColumns, ...AdditionalColumns(EstadoOCA.ingresada)]
};

const OcaMermaObjetadaOrigen: WebEntity<OCA> = {
  ...OcaEntity,
  name: 'ocaMermaObjetadaOrigen' as WebEntityName,
  tableColumns: [...OcaEntity.tableColumns, ...AdditionalColumns(EstadoOCA.objetadaOrigen)]
};

const OcaFaltanteObjetadaOrigen: WebEntity<OCA> = {
  ...OcaEntity,
  name: 'ocaFaltanteObjetadaOrigen' as WebEntityName,
  tableColumns: [...OcaEntity.tableColumns, ...AdditionalColumns(EstadoOCA.objetadaOrigen)]
};

const OcaMermaObjetadaTransportista: WebEntity<OCA> = {
  ...OcaEntity,
  name: 'ocaMermaObjetadaTransportista' as WebEntityName,
  tableColumns: [...OcaEntity.tableColumns, ...AdditionalColumns(EstadoOCA.objetadaTransportista)]
};

const OcaFaltanteObjetadaTransportista: WebEntity<OCA> = {
  ...OcaEntity,
  name: 'ocaFaltanteObjetadaTransportista' as WebEntityName,
  tableColumns: [...OcaEntity.tableColumns, ...AdditionalColumns(EstadoOCA.objetadaTransportista)]
};

const OcaMermaCobrable: WebEntity<OCA> = {
  ...OcaEntity,
  name: 'ocaMermaCobrable' as WebEntityName,
  tableColumns: [...OcaEntity.tableColumns, ...AdditionalColumns(EstadoOCA.cobrable)]
};

const OcaFaltanteCobrable: WebEntity<OCA> = {
  ...OcaEntity,
  name: 'ocaFaltanteCobrable' as WebEntityName,
  tableColumns: [...OcaEntity.tableColumns, ...AdditionalColumns(EstadoOCA.cobrable)]
};

const OcaMermaEnDiscusion: WebEntity<OCA> = {
  ...OcaEntity,
  name: 'ocaMermaEnDiscusion' as WebEntityName,
  tableColumns: [...OcaEntity.tableColumns, ...AdditionalColumns(EstadoOCA.enDiscusion)]
};

const OcaFaltanteEnDiscusion: WebEntity<OCA> = {
  ...OcaEntity,
  name: 'ocaFaltanteEnDiscusion' as WebEntityName,
  tableColumns: [...OcaEntity.tableColumns, ...AdditionalColumns(EstadoOCA.enDiscusion)]
};

const OcaMermaCerrada: WebEntity<OCA> = {
  ...OcaEntity,
  name: 'ocaMermaCerrada' as WebEntityName,
  tableColumns: [...OcaEntity.tableColumns, ...AdditionalColumns(EstadoOCA.cerrada)]
};

const OcaFaltanteCerrada: WebEntity<OCA> = {
  ...OcaEntity,
  name: 'ocaFaltanteCerrada' as WebEntityName,
  tableColumns: [...OcaEntity.tableColumns, ...AdditionalColumns(EstadoOCA.cerrada)]
};

const OcaMermaExpirada: WebEntity<OCA> = {
  ...OcaEntity,
  name: 'ocaMermaExpirada' as WebEntityName,
  tableColumns: [...OcaEntity.tableColumns, ...AdditionalColumns(EstadoOCA.expirada)]
};

const OcaFaltanteExpirada: WebEntity<OCA> = {
  ...OcaEntity,
  name: 'ocaFaltanteExpirada' as WebEntityName,
  tableColumns: [...OcaEntity.tableColumns, ...AdditionalColumns(EstadoOCA.expirada)]
};

export {
  UsuarioTransportistaEntity,
  UsuarioEntity,
  TransportistasEntity,
  TipoCausalEntity,
  OcaMermaIngresada,
  OcaFaltanteIngresada,
  OcaMermaObjetadaOrigen,
  OcaFaltanteObjetadaOrigen,
  OcaMermaObjetadaTransportista,
  OcaFaltanteObjetadaTransportista,
  OcaMermaCobrable,
  OcaFaltanteCobrable,
  OcaMermaEnDiscusion,
  OcaFaltanteEnDiscusion,
  CobroEntity,
  OcaMermaCerrada,
  OcaFaltanteCerrada,
  OcaMermaExpirada,
  OcaFaltanteExpirada,
  DashboardEntity,
  EnvasesAndPalletComplianceEntity
};
